import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DialogContainer from '../DialogContainer/DialogContainer'
import Button from '@material-ui/core/Button/Button'
import { useFirstTime } from '../../hooks/useFirstTime'
import Confetti from 'react-dom-confetti'

type Props = {
  /** shop name */
  shopName: string
  /** search from location of router-dom */
  search: string
  /** shop url */
  shopUrl: string
}

const useStyles = makeStyles((theme: any) => ({
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
    position: 'absolute',
    marginTop: '300px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'stretch',
    maxWidth: 500,
    width: '100%',
    paddingTop: 4,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '25px 10%',
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#393939',
    marginBottom: 15,
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#999999',
    textAlign: 'center',
  },
  rootStyle: {
    height: 50,
    borderRadius: 10,
    background: '#9a9a9a08',
  },
  labelStyle: {
    top: -1,
    fontFamily: 'Nunito',
    fontSize: 16,
    letterSpacing: '-0.02em',
    color: '#999999',
  },
  input: {
    ...theme.styles.input,
  },
  button: {
    ...theme.styles.button,
    margin: 15,
    height: 50,
    width: '90%',
    ...theme.styles.blueButton,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 35,
    },
  },
}))

export function FirstTimeDialog({ search, shopName, shopUrl }: Props) {
  const classes = useStyles()

  const isFirstTimeUrl = new URLSearchParams(search).get('first_time')
  const [isFirstTime, setFirstTime] = useFirstTime(shopUrl)

  const onClose = () => {
    setFirstTime(false)
  }

  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 120,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  }

  const [active, setActive] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setActive(true), 1000)

    return () => clearTimeout(timer)
  }, [])

  const name = shopName?.split(`'s Shop`)[0]
  return (
    <>
      {isFirstTimeUrl && isFirstTime && (
        <div className={classes.overlay}>
          <Confetti active={active} config={config} />
        </div>
      )}
      <DialogContainer
        open={!!(isFirstTimeUrl && isFirstTime)}
        onClose={onClose}
      >
        <div className={classes.root}>
          {isFirstTimeUrl && isFirstTime && (
            <div className={classes.content}>
              <span className={classes.title}>
                Welcome to your storefront {name}!
              </span>
              <span className={classes.subTitle}>
                This is your MyShop store front, you can now share this link and
                commission on all the sales you generate!
              </span>
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={onClose}
          >
            Let’s Go!
          </Button>
        </div>
      </DialogContainer>
    </>
  )
}

export default FirstTimeDialog
