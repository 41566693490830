import { Currency } from '../../types'

export const currencies: Array<Currency> = [
  {
    name: 'US Dollar',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'USD',
    namePlural: 'US dollars',
    countries: [
      'AS',
      'BQ',
      'EC',
      'FM',
      'GU',
      'IO',
      'MH',
      'MP',
      'PR',
      'PW',
      'SV',
      'TC',
      'TL',
      'UM',
      'US',
      'VG',
      'VI',
    ],
  },
  {
    name: 'Canadian Dollar',
    symbol: 'CA$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'CAD',
    namePlural: 'Canadian dollars',
    countries: ['CA'],
  },
  {
    name: 'Euro',
    symbol: '€',
    symbolNative: '€',
    decimalDigits: 2,
    rounding: 0,
    code: 'EUR',
    namePlural: 'euros',
    countries: [
      'AD',
      'AT',
      'AX',
      'BE',
      'BL',
      'CY',
      'DE',
      'EE',
      'ES',
      'FI',
      'FR',
      'GF',
      'GP',
      'GR',
      'IE',
      'IT',
      'LT',
      'LU',
      'LV',
      'MC',
      'ME',
      'MF',
      'MQ',
      'MT',
      'NL',
      'PM',
      'PT',
      'RE',
      'SI',
      'SK',
      'SM',
      'TF',
      'VA',
      'XK',
      'YT',
    ],
  },
  {
    name: 'United Arab Emirates Dirham',
    symbol: 'AED',
    symbolNative: 'د.إ.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'AED',
    namePlural: 'UAE dirhams',
    countries: ['AE'],
  },
  {
    name: 'Afghan Afghani',
    symbol: 'Af',
    symbolNative: '؋',
    decimalDigits: 0,
    rounding: 0,
    code: 'AFN',
    namePlural: 'Afghan Afghanis',
    countries: ['AF'],
  },
  {
    name: 'Albanian Lek',
    symbol: 'ALL',
    symbolNative: 'Lek',
    decimalDigits: 0,
    rounding: 0,
    code: 'ALL',
    namePlural: 'Albanian lekë',
    countries: ['AL'],
  },
  {
    name: 'Armenian Dram',
    symbol: 'AMD',
    symbolNative: 'դր.',
    decimalDigits: 0,
    rounding: 0,
    code: 'AMD',
    namePlural: 'Armenian drams',
    countries: ['AM'],
  },
  {
    name: 'Argentine Peso',
    symbol: 'AR$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'ARS',
    namePlural: 'Argentine pesos',
    countries: ['AR'],
  },
  {
    name: 'Australian Dollar',
    symbol: 'AU$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'AUD',
    namePlural: 'Australian dollars',
    countries: ['AU', 'CC', 'CX', 'HM', 'KI', 'NF', 'NR', 'TV'],
  },
  {
    name: 'Azerbaijani Manat',
    symbol: 'man.',
    symbolNative: 'ман.',
    decimalDigits: 2,
    rounding: 0,
    code: 'AZN',
    namePlural: 'Azerbaijani manats',
    countries: ['AZ'],
  },
  {
    name: 'Bosnia-Herzegovina Convertible Mark',
    symbol: 'KM',
    symbolNative: 'KM',
    decimalDigits: 2,
    rounding: 0,
    code: 'BAM',
    namePlural: 'Bosnia-Herzegovina convertible marks',
    countries: ['BA'],
  },
  {
    name: 'Bangladeshi Taka',
    symbol: 'Tk',
    symbolNative: '৳',
    decimalDigits: 2,
    rounding: 0,
    code: 'BDT',
    namePlural: 'Bangladeshi takas',
    countries: ['BD'],
  },
  {
    name: 'Bulgarian Lev',
    symbol: 'BGN',
    symbolNative: 'лв.',
    decimalDigits: 2,
    rounding: 0,
    code: 'BGN',
    namePlural: 'Bulgarian leva',
    countries: ['BG'],
  },
  {
    name: 'Bahraini Dinar',
    symbol: 'BD',
    symbolNative: 'د.ب.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'BHD',
    namePlural: 'Bahraini dinars',
    countries: ['BH'],
  },
  {
    name: 'Burundian Franc',
    symbol: 'FBu',
    symbolNative: 'FBu',
    decimalDigits: 0,
    rounding: 0,
    code: 'BIF',
    namePlural: 'Burundian francs',
    countries: ['BI'],
  },
  {
    name: 'Brunei Dollar',
    symbol: 'BN$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'BND',
    namePlural: 'Brunei dollars',
    countries: ['BN'],
  },
  {
    name: 'Bolivian Boliviano',
    symbol: 'Bs',
    symbolNative: 'Bs',
    decimalDigits: 2,
    rounding: 0,
    code: 'BOB',
    namePlural: 'Bolivian bolivianos',
    countries: ['BO'],
  },
  {
    name: 'Brazilian Real',
    symbol: 'R$',
    symbolNative: 'R$',
    decimalDigits: 2,
    rounding: 0,
    code: 'BRL',
    namePlural: 'Brazilian reals',
    countries: ['BR'],
  },
  {
    name: 'Botswanan Pula',
    symbol: 'BWP',
    symbolNative: 'P',
    decimalDigits: 2,
    rounding: 0,
    code: 'BWP',
    namePlural: 'Botswanan pulas',
    countries: ['BW'],
  },
  {
    name: 'Belarusian Ruble',
    symbol: 'Br',
    symbolNative: 'руб.',
    decimalDigits: 2,
    rounding: 0,
    code: 'BYN',
    namePlural: 'Belarusian rubles',
    countries: ['BY'],
  },
  {
    name: 'Belize Dollar',
    symbol: 'BZ$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'BZD',
    namePlural: 'Belize dollars',
    countries: ['BZ'],
  },
  {
    name: 'Congolese Franc',
    symbol: 'CDF',
    symbolNative: 'FrCD',
    decimalDigits: 2,
    rounding: 0,
    code: 'CDF',
    namePlural: 'Congolese francs',
    countries: ['CD'],
  },
  {
    name: 'Swiss Franc',
    symbol: 'CHF',
    symbolNative: 'CHF',
    decimalDigits: 2,
    rounding: 0.05,
    code: 'CHF',
    namePlural: 'Swiss francs',
    countries: ['CH', 'LI'],
  },
  {
    name: 'Chilean Peso',
    symbol: 'CL$',
    symbolNative: '$',
    decimalDigits: 0,
    rounding: 0,
    code: 'CLP',
    namePlural: 'Chilean pesos',
    countries: ['CL'],
  },
  {
    name: 'Chinese Yuan',
    symbol: 'CN¥',
    symbolNative: 'CN¥',
    decimalDigits: 2,
    rounding: 0,
    code: 'CNY',
    namePlural: 'Chinese yuan',
    countries: ['CN'],
  },
  {
    name: 'Colombian Peso',
    symbol: 'CO$',
    symbolNative: '$',
    decimalDigits: 0,
    rounding: 0,
    code: 'COP',
    namePlural: 'Colombian pesos',
    countries: ['CO'],
  },
  {
    name: 'Costa Rican Colón',
    symbol: '₡',
    symbolNative: '₡',
    decimalDigits: 0,
    rounding: 0,
    code: 'CRC',
    namePlural: 'Costa Rican colóns',
    countries: ['CR'],
  },
  {
    name: 'Cape Verdean Escudo',
    symbol: 'CV$',
    symbolNative: 'CV$',
    decimalDigits: 2,
    rounding: 0,
    code: 'CVE',
    namePlural: 'Cape Verdean escudos',
    countries: ['CV'],
  },
  {
    name: 'Czech Republic Koruna',
    symbol: 'Kč',
    symbolNative: 'Kč',
    decimalDigits: 2,
    rounding: 0,
    code: 'CZK',
    namePlural: 'Czech Republic korunas',
    countries: ['CZ'],
  },
  {
    name: 'Djiboutian Franc',
    symbol: 'Fdj',
    symbolNative: 'Fdj',
    decimalDigits: 0,
    rounding: 0,
    code: 'DJF',
    namePlural: 'Djiboutian francs',
    countries: ['DJ'],
  },
  {
    name: 'Danish Krone',
    symbol: 'Dkr',
    symbolNative: 'kr',
    decimalDigits: 2,
    rounding: 0,
    code: 'DKK',
    namePlural: 'Danish kroner',
    countries: ['DK', 'FO', 'GL'],
  },
  {
    name: 'Dominican Peso',
    symbol: 'RD$',
    symbolNative: 'RD$',
    decimalDigits: 2,
    rounding: 0,
    code: 'DOP',
    namePlural: 'Dominican pesos',
    countries: ['DO'],
  },
  {
    name: 'Algerian Dinar',
    symbol: 'DA',
    symbolNative: 'د.ج.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'DZD',
    namePlural: 'Algerian dinars',
    countries: ['DZ'],
  },
  {
    name: 'Estonian Kroon',
    symbol: 'Ekr',
    symbolNative: 'kr',
    decimalDigits: 2,
    rounding: 0,
    code: 'EEK',
    namePlural: 'Estonian kroons',
    countries: ['EE'],
  },
  {
    name: 'Egyptian Pound',
    symbol: 'EGP',
    symbolNative: 'ج.م.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'EGP',
    namePlural: 'Egyptian pounds',
    countries: ['EG'],
  },
  {
    name: 'Eritrean Nakfa',
    symbol: 'Nfk',
    symbolNative: 'Nfk',
    decimalDigits: 2,
    rounding: 0,
    code: 'ERN',
    namePlural: 'Eritrean nakfas',
    countries: ['ER'],
  },
  {
    name: 'Ethiopian Birr',
    symbol: 'Br',
    symbolNative: 'Br',
    decimalDigits: 2,
    rounding: 0,
    code: 'ETB',
    namePlural: 'Ethiopian birrs',
    countries: ['ET'],
  },
  {
    name: 'British Pound Sterling',
    symbol: '£',
    symbolNative: '£',
    decimalDigits: 2,
    rounding: 0,
    code: 'GBP',
    namePlural: 'British pounds sterling',
    countries: ['GB', 'GG', 'GS', 'IM', 'JE'],
  },
  {
    name: 'Georgian Lari',
    symbol: 'GEL',
    symbolNative: 'GEL',
    decimalDigits: 2,
    rounding: 0,
    code: 'GEL',
    namePlural: 'Georgian laris',
    countries: ['GE'],
  },
  {
    name: 'Ghanaian Cedi',
    symbol: 'GH₵',
    symbolNative: 'GH₵',
    decimalDigits: 2,
    rounding: 0,
    code: 'GHS',
    namePlural: 'Ghanaian cedis',
    countries: ['GH'],
  },
  {
    name: 'Guinean Franc',
    symbol: 'FG',
    symbolNative: 'FG',
    decimalDigits: 0,
    rounding: 0,
    code: 'GNF',
    namePlural: 'Guinean francs',
    countries: ['GN'],
  },
  {
    name: 'Guatemalan Quetzal',
    symbol: 'GTQ',
    symbolNative: 'Q',
    decimalDigits: 2,
    rounding: 0,
    code: 'GTQ',
    namePlural: 'Guatemalan quetzals',
    countries: ['GT'],
  },
  {
    name: 'Hong Kong Dollar',
    symbol: 'HK$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'HKD',
    namePlural: 'Hong Kong dollars',
    countries: ['HK'],
  },
  {
    name: 'Honduran Lempira',
    symbol: 'HNL',
    symbolNative: 'L',
    decimalDigits: 2,
    rounding: 0,
    code: 'HNL',
    namePlural: 'Honduran lempiras',
    countries: ['HN'],
  },
  {
    name: 'Croatian Kuna',
    symbol: 'kn',
    symbolNative: 'kn',
    decimalDigits: 2,
    rounding: 0,
    code: 'HRK',
    namePlural: 'Croatian kunas',
    countries: ['HR'],
  },
  {
    name: 'Hungarian Forint',
    symbol: 'Ft',
    symbolNative: 'Ft',
    decimalDigits: 0,
    rounding: 0,
    code: 'HUF',
    namePlural: 'Hungarian forints',
    countries: ['HU'],
  },
  {
    name: 'Indonesian Rupiah',
    symbol: 'Rp',
    symbolNative: 'Rp',
    decimalDigits: 0,
    rounding: 0,
    code: 'IDR',
    namePlural: 'Indonesian rupiahs',
    countries: ['ID'],
  },
  {
    name: 'Israeli New Sheqel',
    symbol: '₪',
    symbolNative: '₪',
    decimalDigits: 2,
    rounding: 0,
    code: 'ILS',
    namePlural: 'Israeli new sheqels',
    countries: ['IL', 'PS'],
  },
  {
    name: 'Indian Rupee',
    symbol: 'Rs',
    symbolNative: 'টকা',
    decimalDigits: 2,
    rounding: 0,
    code: 'INR',
    namePlural: 'Indian rupees',
    countries: ['IN'],
  },
  {
    name: 'Iraqi Dinar',
    symbol: 'IQD',
    symbolNative: 'د.ع.‏',
    decimalDigits: 0,
    rounding: 0,
    code: 'IQD',
    namePlural: 'Iraqi dinars',
    countries: ['IQ'],
  },
  {
    name: 'Iranian Rial',
    symbol: 'IRR',
    symbolNative: '﷼',
    decimalDigits: 0,
    rounding: 0,
    code: 'IRR',
    namePlural: 'Iranian rials',
    countries: ['IR'],
  },
  {
    name: 'Icelandic Króna',
    symbol: 'Ikr',
    symbolNative: 'kr',
    decimalDigits: 0,
    rounding: 0,
    code: 'ISK',
    namePlural: 'Icelandic krónur',
    countries: ['IS'],
  },
  {
    name: 'Jamaican Dollar',
    symbol: 'J$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'JMD',
    namePlural: 'Jamaican dollars',
    countries: ['JM'],
  },
  {
    name: 'Jordanian Dinar',
    symbol: 'JD',
    symbolNative: 'د.أ.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'JOD',
    namePlural: 'Jordanian dinars',
    countries: ['JO'],
  },
  {
    name: 'Japanese Yen',
    symbol: '¥',
    symbolNative: '￥',
    decimalDigits: 0,
    rounding: 0,
    code: 'JPY',
    namePlural: 'Japanese yen',
    countries: ['JP'],
  },
  {
    name: 'Kenyan Shilling',
    symbol: 'Ksh',
    symbolNative: 'Ksh',
    decimalDigits: 2,
    rounding: 0,
    code: 'KES',
    namePlural: 'Kenyan shillings',
    countries: ['KE'],
  },
  {
    name: 'Cambodian Riel',
    symbol: 'KHR',
    symbolNative: '៛',
    decimalDigits: 2,
    rounding: 0,
    code: 'KHR',
    namePlural: 'Cambodian riels',
    countries: ['KH'],
  },
  {
    name: 'Comorian Franc',
    symbol: 'CF',
    symbolNative: 'FC',
    decimalDigits: 0,
    rounding: 0,
    code: 'KMF',
    namePlural: 'Comorian francs',
    countries: ['KM'],
  },
  {
    name: 'South Korean Won',
    symbol: '₩',
    symbolNative: '₩',
    decimalDigits: 0,
    rounding: 0,
    code: 'KRW',
    namePlural: 'South Korean won',
    countries: ['KR'],
  },
  {
    name: 'Kuwaiti Dinar',
    symbol: 'KD',
    symbolNative: 'د.ك.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'KWD',
    namePlural: 'Kuwaiti dinars',
    countries: ['KW'],
  },
  {
    name: 'Kazakhstani Tenge',
    symbol: 'KZT',
    symbolNative: 'тңг.',
    decimalDigits: 2,
    rounding: 0,
    code: 'KZT',
    namePlural: 'Kazakhstani tenges',
    countries: ['KZ'],
  },
  {
    name: 'Lebanese Pound',
    symbol: 'LB£',
    symbolNative: 'ل.ل.‏',
    decimalDigits: 0,
    rounding: 0,
    code: 'LBP',
    namePlural: 'Lebanese pounds',
    countries: ['LB'],
  },
  {
    name: 'Sri Lankan Rupee',
    symbol: 'SLRs',
    symbolNative: 'SL Re',
    decimalDigits: 2,
    rounding: 0,
    code: 'LKR',
    namePlural: 'Sri Lankan rupees',
    countries: ['LK'],
  },
  {
    name: 'Lithuanian Litas',
    symbol: 'Lt',
    symbolNative: 'Lt',
    decimalDigits: 2,
    rounding: 0,
    code: 'LTL',
    namePlural: 'Lithuanian litai',
    countries: ['LT'],
  },
  {
    name: 'Latvian Lats',
    symbol: 'Ls',
    symbolNative: 'Ls',
    decimalDigits: 2,
    rounding: 0,
    code: 'LVL',
    namePlural: 'Latvian lati',
    countries: ['LV'],
  },
  {
    name: 'Libyan Dinar',
    symbol: 'LD',
    symbolNative: 'د.ل.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'LYD',
    namePlural: 'Libyan dinars',
    countries: ['LY'],
  },
  {
    name: 'Moroccan Dirham',
    symbol: 'MAD',
    symbolNative: 'د.م.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'MAD',
    namePlural: 'Moroccan dirhams',
    countries: ['EH', 'MA'],
  },
  {
    name: 'Moldovan Leu',
    symbol: 'MDL',
    symbolNative: 'MDL',
    decimalDigits: 2,
    rounding: 0,
    code: 'MDL',
    namePlural: 'Moldovan lei',
    countries: ['MD'],
  },
  {
    name: 'Malagasy Ariary',
    symbol: 'MGA',
    symbolNative: 'MGA',
    decimalDigits: 0,
    rounding: 0,
    code: 'MGA',
    namePlural: 'Malagasy Ariaries',
    countries: ['MG'],
  },
  {
    name: 'Macedonian Denar',
    symbol: 'MKD',
    symbolNative: 'MKD',
    decimalDigits: 2,
    rounding: 0,
    code: 'MKD',
    namePlural: 'Macedonian denari',
    countries: ['MK'],
  },
  {
    name: 'Myanma Kyat',
    symbol: 'MMK',
    symbolNative: 'K',
    decimalDigits: 0,
    rounding: 0,
    code: 'MMK',
    namePlural: 'Myanma kyats',
    countries: ['MM'],
  },
  {
    name: 'Macanese Pataca',
    symbol: 'MOP$',
    symbolNative: 'MOP$',
    decimalDigits: 2,
    rounding: 0,
    code: 'MOP',
    namePlural: 'Macanese patacas',
    countries: ['MO'],
  },
  {
    name: 'Mauritian Rupee',
    symbol: 'MURs',
    symbolNative: 'MURs',
    decimalDigits: 0,
    rounding: 0,
    code: 'MUR',
    namePlural: 'Mauritian rupees',
    countries: ['MU'],
  },
  {
    name: 'Mexican Peso',
    symbol: 'MX$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'MXN',
    namePlural: 'Mexican pesos',
    countries: ['MX'],
  },
  {
    name: 'Malaysian Ringgit',
    symbol: 'RM',
    symbolNative: 'RM',
    decimalDigits: 2,
    rounding: 0,
    code: 'MYR',
    namePlural: 'Malaysian ringgits',
    countries: ['MY'],
  },
  {
    name: 'Mozambican Metical',
    symbol: 'MTn',
    symbolNative: 'MTn',
    decimalDigits: 2,
    rounding: 0,
    code: 'MZN',
    namePlural: 'Mozambican meticals',
    countries: ['MZ'],
  },
  {
    name: 'Namibian Dollar',
    symbol: 'N$',
    symbolNative: 'N$',
    decimalDigits: 2,
    rounding: 0,
    code: 'NAD',
    namePlural: 'Namibian dollars',
    countries: ['NA'],
  },
  {
    name: 'Nigerian Naira',
    symbol: '₦',
    symbolNative: '₦',
    decimalDigits: 2,
    rounding: 0,
    code: 'NGN',
    namePlural: 'Nigerian nairas',
    countries: ['NG'],
  },
  {
    name: 'Nicaraguan Córdoba',
    symbol: 'C$',
    symbolNative: 'C$',
    decimalDigits: 2,
    rounding: 0,
    code: 'NIO',
    namePlural: 'Nicaraguan córdobas',
    countries: ['NI'],
  },
  {
    name: 'Norwegian Krone',
    symbol: 'Nkr',
    symbolNative: 'kr',
    decimalDigits: 2,
    rounding: 0,
    code: 'NOK',
    namePlural: 'Norwegian kroner',
    countries: ['BV', 'NO', 'SJ'],
  },
  {
    name: 'Nepalese Rupee',
    symbol: 'NPRs',
    symbolNative: 'नेरू',
    decimalDigits: 2,
    rounding: 0,
    code: 'NPR',
    namePlural: 'Nepalese rupees',
    countries: ['NP'],
  },
  {
    name: 'New Zealand Dollar',
    symbol: 'NZ$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'NZD',
    namePlural: 'New Zealand dollars',
    countries: ['CK', 'NU', 'NZ', 'PN', 'TK'],
  },
  {
    name: 'Omani Rial',
    symbol: 'OMR',
    symbolNative: 'ر.ع.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'OMR',
    namePlural: 'Omani rials',
    countries: ['OM'],
  },
  {
    name: 'Panamanian Balboa',
    symbol: 'B/.',
    symbolNative: 'B/.',
    decimalDigits: 2,
    rounding: 0,
    code: 'PAB',
    namePlural: 'Panamanian balboas',
    countries: ['PA'],
  },
  {
    name: 'Peruvian Nuevo Sol',
    symbol: 'S/.',
    symbolNative: 'S/.',
    decimalDigits: 2,
    rounding: 0,
    code: 'PEN',
    namePlural: 'Peruvian nuevos soles',
    countries: ['PE'],
  },
  {
    name: 'Philippine Peso',
    symbol: '₱',
    symbolNative: '₱',
    decimalDigits: 2,
    rounding: 0,
    code: 'PHP',
    namePlural: 'Philippine pesos',
    countries: ['PH'],
  },
  {
    name: 'Pakistani Rupee',
    symbol: 'PKRs',
    symbolNative: '₨',
    decimalDigits: 0,
    rounding: 0,
    code: 'PKR',
    namePlural: 'Pakistani rupees',
    countries: ['PK'],
  },
  {
    name: 'Polish Zloty',
    symbol: 'zł',
    symbolNative: 'zł',
    decimalDigits: 2,
    rounding: 0,
    code: 'PLN',
    namePlural: 'Polish zlotys',
    countries: ['PL'],
  },
  {
    name: 'Paraguayan Guarani',
    symbol: '₲',
    symbolNative: '₲',
    decimalDigits: 0,
    rounding: 0,
    code: 'PYG',
    namePlural: 'Paraguayan guaranis',
    countries: ['PY'],
  },
  {
    name: 'Qatari Rial',
    symbol: 'QR',
    symbolNative: 'ر.ق.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'QAR',
    namePlural: 'Qatari rials',
    countries: ['QA'],
  },
  {
    name: 'Romanian Leu',
    symbol: 'RON',
    symbolNative: 'RON',
    decimalDigits: 2,
    rounding: 0,
    code: 'RON',
    namePlural: 'Romanian lei',
    countries: ['RO'],
  },
  {
    name: 'Serbian Dinar',
    symbol: 'din.',
    symbolNative: 'дин.',
    decimalDigits: 0,
    rounding: 0,
    code: 'RSD',
    namePlural: 'Serbian dinars',
    countries: ['RS'],
  },
  {
    name: 'Russian Ruble',
    symbol: 'RUB',
    symbolNative: '₽.',
    decimalDigits: 2,
    rounding: 0,
    code: 'RUB',
    namePlural: 'Russian rubles',
    countries: ['RU'],
  },
  {
    name: 'Rwandan Franc',
    symbol: 'RWF',
    symbolNative: 'FR',
    decimalDigits: 0,
    rounding: 0,
    code: 'RWF',
    namePlural: 'Rwandan francs',
    countries: ['RW'],
  },
  {
    name: 'Saudi Riyal',
    symbol: 'SR',
    symbolNative: 'ر.س.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'SAR',
    namePlural: 'Saudi riyals',
    countries: ['SA'],
  },
  {
    name: 'Sudanese Pound',
    symbol: 'SDG',
    symbolNative: 'SDG',
    decimalDigits: 2,
    rounding: 0,
    code: 'SDG',
    namePlural: 'Sudanese pounds',
    countries: ['SD'],
  },
  {
    name: 'Swedish Krona',
    symbol: 'Skr',
    symbolNative: 'kr',
    decimalDigits: 2,
    rounding: 0,
    code: 'SEK',
    namePlural: 'Swedish kronor',
    countries: ['SE'],
  },
  {
    name: 'Singapore Dollar',
    symbol: 'S$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'SGD',
    namePlural: 'Singapore dollars',
    countries: ['SG'],
  },
  {
    name: 'Somali Shilling',
    symbol: 'Ssh',
    symbolNative: 'Ssh',
    decimalDigits: 0,
    rounding: 0,
    code: 'SOS',
    namePlural: 'Somali shillings',
    countries: ['SO'],
  },
  {
    name: 'Syrian Pound',
    symbol: 'SY£',
    symbolNative: 'ل.س.‏',
    decimalDigits: 0,
    rounding: 0,
    code: 'SYP',
    namePlural: 'Syrian pounds',
    countries: ['SY'],
  },
  {
    name: 'Thai Baht',
    symbol: '฿',
    symbolNative: '฿',
    decimalDigits: 2,
    rounding: 0,
    code: 'THB',
    namePlural: 'Thai baht',
    countries: ['TH'],
  },
  {
    name: 'Tunisian Dinar',
    symbol: 'DT',
    symbolNative: 'د.ت.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'TND',
    namePlural: 'Tunisian dinars',
    countries: ['TN'],
  },
  {
    name: 'Tongan Paʻanga',
    symbol: 'T$',
    symbolNative: 'T$',
    decimalDigits: 2,
    rounding: 0,
    code: 'TOP',
    namePlural: 'Tongan paʻanga',
    countries: ['TO'],
  },
  {
    name: 'Turkish Lira',
    symbol: 'TL',
    symbolNative: 'TL',
    decimalDigits: 2,
    rounding: 0,
    code: 'TRY',
    namePlural: 'Turkish Lira',
    countries: ['TR'],
  },
  {
    name: 'Trinidad and Tobago Dollar',
    symbol: 'TT$',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'TTD',
    namePlural: 'Trinidad and Tobago dollars',
    countries: ['TT'],
  },
  {
    name: 'New Taiwan Dollar',
    symbol: 'NT$',
    symbolNative: 'NT$',
    decimalDigits: 2,
    rounding: 0,
    code: 'TWD',
    namePlural: 'New Taiwan dollars',
    countries: ['TW'],
  },
  {
    name: 'Tanzanian Shilling',
    symbol: 'TSh',
    symbolNative: 'TSh',
    decimalDigits: 0,
    rounding: 0,
    code: 'TZS',
    namePlural: 'Tanzanian shillings',
    countries: ['TZ'],
  },
  {
    name: 'Ukrainian Hryvnia',
    symbol: '₴',
    symbolNative: '₴',
    decimalDigits: 2,
    rounding: 0,
    code: 'UAH',
    namePlural: 'Ukrainian hryvnias',
    countries: ['UA'],
  },
  {
    name: 'Ugandan Shilling',
    symbol: 'USh',
    symbolNative: 'USh',
    decimalDigits: 0,
    rounding: 0,
    code: 'UGX',
    namePlural: 'Ugandan shillings',
    countries: ['UG'],
  },
  {
    name: 'Uruguayan Peso',
    symbol: '$U',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'UYU',
    namePlural: 'Uruguayan pesos',
    countries: ['UY'],
  },
  {
    name: 'Uzbekistan Som',
    symbol: 'UZS',
    symbolNative: 'UZS',
    decimalDigits: 0,
    rounding: 0,
    code: 'UZS',
    namePlural: 'Uzbekistan som',
    countries: ['UZ'],
  },
  {
    name: 'Venezuelan Bolívar',
    symbol: 'Bs.F.',
    symbolNative: 'Bs.F.',
    decimalDigits: 2,
    rounding: 0,
    code: 'VEF',
    namePlural: 'Venezuelan bolívars',
    countries: ['VE'],
  },
  {
    name: 'Vietnamese Dong',
    symbol: '₫',
    symbolNative: '₫',
    decimalDigits: 0,
    rounding: 0,
    code: 'VND',
    namePlural: 'Vietnamese dong',
    countries: ['VN'],
  },
  {
    name: 'CFA Franc BEAC',
    symbol: 'FCFA',
    symbolNative: 'FCFA',
    decimalDigits: 0,
    rounding: 0,
    code: 'XAF',
    namePlural: 'CFA francs BEAC',
    countries: ['CF', 'CG', 'CM', 'GA', 'GQ', 'TD'],
  },
  {
    name: 'CFA Franc BCEAO',
    symbol: 'CFA',
    symbolNative: 'CFA',
    decimalDigits: 0,
    rounding: 0,
    code: 'XOF',
    namePlural: 'CFA francs BCEAO',
    countries: ['BF', 'BJ', 'CI', 'GW', 'ML', 'NE', 'SN', 'TG'],
  },
  {
    name: 'Yemeni Rial',
    symbol: 'YR',
    symbolNative: 'ر.ي.‏',
    decimalDigits: 0,
    rounding: 0,
    code: 'YER',
    namePlural: 'Yemeni rials',
    countries: ['YE'],
  },
  {
    name: 'South African Rand',
    symbol: 'R',
    symbolNative: 'R',
    decimalDigits: 2,
    rounding: 0,
    code: 'ZAR',
    namePlural: 'South African rand',
    countries: ['ZA'],
  },
  {
    name: 'Zambian Kwacha',
    symbol: 'ZK',
    symbolNative: 'ZK',
    decimalDigits: 0,
    rounding: 0,
    code: 'ZMK',
    namePlural: 'Zambian kwachas',
    countries: ['ZM'],
  },
  {
    name: 'Zimbabwean Dollar',
    symbol: 'ZWL$',
    symbolNative: 'ZWL$',
    decimalDigits: 0,
    rounding: 0,
    code: 'ZWL',
    namePlural: 'Zimbabwean Dollar',
    countries: ['ZW'],
  },
]
