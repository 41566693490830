import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import SellingPoints from '../SellingPoints'
import { Typography } from '@material-ui/core'
import FullScreenBadge from '../FullScreenBadge'
import cn from 'classnames'
import GoogleAnalytics from '../../lib/GoogleAnalytics'
const useStyles = makeStyles((theme: any) => ({
  badgesContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: '-5px -1px 5px rgba(0, 0, 0, 0.04)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
  },
  badges: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: 69,
    display: 'flex',
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    backgroundColor: theme.palette.white,
    '-webkit-overflow-scrolling': 'touch',

    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopRoot: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  wrapper: {
    width: '100%',
    height: 69,
    position: 'relative',
    borderTop: '1px solid rgba(0, 0, 0, 0.05)',
  },
  wrapperDesktop: {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderTop: 'none',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sellingPointsContainer: {
    height: '100%',
    width: '100%',
  },
  icon: {
    width: 16,
    marginLeft: 14,
  },
  achivments: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    fontStyle: 'normal',
    // fontWeight: '600',
    fontSize: '5px',
    lineHeight: '7px',
    /* identical to box height */

    letterSpacing: '1px',

    color: 'rgba(0, 0, 0, 0.4)',
    marginLeft: 9,
    marginRight: 14,
  },
  desktopAchivments: {
    fontStyle: 'normal',
    // fontWeight: '600',
    fontSize: '10px',
    lineHeight: '7px',
    /* identical to box height */
    marginTop: 25,
    marginBottom: 10,
    letterSpacing: '1px',

    color: 'rgba(0, 0, 0, 0.4)',
    display: 'block',
  },
  badge: {
    backgroundColor: theme.palette.white,
    opacity: 0.85,
    border: '1px solid rgba(140, 140, 140, 0.3)',
    boxSizing: 'border-box',
    boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.07)',
    width: 45,
    minWidth: 45,
    maxWidth: 45,
    height: 45,
    borderRadius: '100%',
    marginRight: 21,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    cursor: 'pointer',
  },
  marginBottom: {
    marginBottom: 10,
  },
  wrap: {
    flexWrap: 'wrap',
  },
}))

export default function Badges(props: {
  onlyDesktop?: boolean
  logo: string
  shopName: string
  achievements: any[]
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [index, setIndex] = React.useState(false)

  const handleClickOpen = index => {
    GoogleAnalytics.track({
      category: 'Achievements',
      action: 'Open Achievements Story Mode',
    })
    setOpen(true)
    setIndex(index)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div
      className={cn(
        classes.wrapper,
        props.onlyDesktop && classes.wrapperDesktop,
      )}
    >
      {!props.onlyDesktop && (
        <div className={classes.sellingPointsContainer}>
          <SellingPoints />
        </div>
      )}
      {!props.onlyDesktop && (
        <div className={classes.root}>
          <div className={classes.badgesContainer}>
            <Typography variant="caption" className={classes.achivments}>
              ACHIEVEMENTS
            </Typography>
            <div className={classes.badges}>
              {props.achievements.map((achievement, index) => (
                <div
                  key={index}
                  onClick={() => handleClickOpen(index)}
                  className={classes.badge}
                >
                  {achievement.emoji}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {props.onlyDesktop && (
        <div className={classes.desktopRoot}>
          <Typography variant="caption" className={classes.desktopAchivments}>
            ACHIEVEMENTS
          </Typography>
          <div className={cn(classes.badges, classes.wrap)}>
            {props.achievements.map((achievement, index) => (
              <div
                key={index}
                onClick={() => handleClickOpen(index)}
                className={cn(classes.badge, classes.marginBottom)}
              >
                {achievement.emoji}
              </div>
            ))}
          </div>
        </div>
      )}
      <FullScreenBadge
        index={index}
        achievements={props.achievements}
        open={open}
        handleClose={handleClose}
        logo={props.logo}
        shopName={props.shopName}
      />
    </div>
  )
}
