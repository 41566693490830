import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { Grid } from '@material-ui/core'
import ShopDetails from '../ShopDetails/index'
import Avatar from '../Avatar/index'
import Badges from '../Badges'
import SellingPoints from '../SellingPoints'

type Props = {
  /** url to the Avatar images  */
  logo: string
  /** products number */
  products?: number
  /** sales number */
  sales?: number
  /** likes number */
  likes?: number
  /** Shop name */
  shopName?: string
  /** Shop bio */
  shopBio?: string
  className?: string
  achievements: any
  selectedCountry: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    width: '100%',
    margin: '0 0 0 0',
  },
}))

export const OwnerDetails = ({
  logo,
  shopName = '',
  shopBio,
  className = '',
  achievements = [],
}: Props) => {
  const classes = useStyles()
  return (
    <div className={cn(classes.root, className)}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item>
          <Avatar logo={logo} diameter={90} isBackground big />
        </Grid>
        <Grid item xs>
          <ShopDetails shopName={shopName} shopBio={shopBio} />
          <Badges
            achievements={achievements}
            onlyDesktop={true}
            logo={logo}
            shopName={shopName}
          />
          <SellingPoints blink />
        </Grid>
      </Grid>
    </div>
  )
}

export default OwnerDetails
