import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckoutForm from '../CheckoutForm/CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getTotalPrice } from '../../lib/getTotalPrice'
import { Address, Currency, Product, Variant } from '../../types'
import { PaymentAccountTypes } from '../../Constants'
import Title from '../Title/Title'
import { getPriceFormatted } from '../../lib/utils'

type Props = {
  onSuccess?: () => void
  product?: Product
  orderDetails?: {
    variant: Variant
    shippingDetails: Address
    billingDetails: Address
  }
  quantity?: number
  onPrevious?: (args: any) => void
  setOrderId: (arg: string) => void
  onClose: () => void
  onAddToOrdersHistory: (arg: string) => void
  /* To convert currency */
  exchangeRate?: number
  currency?: Currency
  shippingFlatRate?: number
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    position: 'relative',
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    marginBottom: 15,
    color: '#000',
  },
  total: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    right: 10,
  },
  price: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    color: '#3F9AF1',
    right: 10,
  },
  usdPrice: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 7,
    marginBottom: 15,
    color: '#3F9AF1',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 200,
  },
  poweredByStripe: {
    width: 100,
    height: 21,
    marginBottom: 15,
  },
  arrowBack: {
    position: 'absolute',
    top: 35,
    left: 25,
  },
  paymentMethods: {
    backgroundImage: 'url("/static/images/cards.svg")',
    height: 25,
    width: 250,
    marginTop: 10,
    backgroundSize: 'cover',
  },
  currency: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 700,
    top: 2,
    right: -20,
  },
  elementsWrapper: {
    width: '100%',
  },
})

const getBrandStripeAccount = (
  variant: Variant,
  brandUsesBBInvoices: boolean,
): string | undefined => {
  if (brandUsesBBInvoices) {
    return
  }
  return process.env.NODE_ENV !== 'production'
    ? process.env.REACT_APP_TEST_STRIPE_CONNECTED_ACCOUNT
    : variant?.stripeAccount
}

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Nunito:400,300,700,500',
    },
  ],
}

export function PaymentDialog({
  product,
  orderDetails,
  quantity,
  onSuccess,
  onPrevious,
  setOrderId,
  onClose,
  onAddToOrdersHistory,
  exchangeRate,
  currency,
  shippingFlatRate,
}: Props) {
  const classes = useStyles()
  const brandUsesBBInvoices =
    product?.brand?.myShopPaymentAccount === PaymentAccountTypes.INVOICING
  const stripeAccount = getBrandStripeAccount(
    orderDetails.variant,
    brandUsesBBInvoices,
  )

  const stripeKey = process.env.REACT_APP_STRIPE_KEY
  const stripePromise = loadStripe(stripeKey, {
    stripeAccount,
  })

  const total = getTotalPrice(
    orderDetails?.variant?.price,
    orderDetails?.variant?.discountPercentage,
    quantity,
    shippingFlatRate,
  )

  return (
    <div className={classes.root}>
      <Title title="Payment" onClose={onClose} onPrevious={onPrevious} />

      <div className={classes.priceContainer}>
        <span className={classes.total}>Total:&nbsp;</span>
        <span className={classes.price}>
          {total && getPriceFormatted(total, currency, exchangeRate)}{' '}
        </span>
      </div>
      <span className={classes.usdPrice}>
        (Card charge: {total?.toFixed(2) || ''} USD)
      </span>

      {(stripeAccount || brandUsesBBInvoices) && (
        <div className={classes.elementsWrapper}>
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <CheckoutForm
              product={product}
              orderDetails={orderDetails}
              quantity={quantity}
              onSuccess={onSuccess}
              setOrderId={setOrderId}
              onAddToOrdersHistory={onAddToOrdersHistory}
              onPrevious={onPrevious}
              shippingFlatRate={shippingFlatRate}
            />
          </Elements>
        </div>
      )}

      <div>
        <img
          className={classes.poweredByStripe}
          alt="stripe logo"
          src="/static/images/powered_by_stripe.svg"
        />
      </div>
    </div>
  )
}

export default PaymentDialog
