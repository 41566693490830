import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import Title from '../Title/Title'
import DialogContainer from '../DialogContainer/DialogContainer'

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  paper: {
    height: 550,
    [theme.breakpoints.up('sm')]: {
      height: 500,
    },
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    color: '#000',
    marginTop: 30,
    marginBottom: 5,
  },
  content: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '16px',
    color: '#393939',
    letterSpacing: '-0.02em',
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 25,
    paddingBottom: 30,
    paddingTop: 10,
  },
}))

type TextDialogProps = {
  title?: string
  content?: string
  onClose?: (...args: any[]) => void
  open?: boolean
  children?: any
  className?: string
}

export function TextDialog({
  title = '',
  content = '',
  onClose,
  open,
  children,
  className,
}: TextDialogProps) {
  const classes = useStyles()

  return (
    <DialogContainer className={classes.paper} open={open} onClose={onClose}>
      <Fragment>
        <div className={cn(classes.root, className)}>
          <Title title={title} onClose={onClose} />
          <p className={classes.content}>{content}</p>
        </div>
        {children}
      </Fragment>
    </DialogContainer>
  )
}
