import React from 'react'
import { Steps } from '../Constants'

export function useStepper() {
  const [step, setStep] = React.useState(0)

  const resetStep = () => {
    setStep(Steps.NO_DIALOG)
  }
  const goToNextStep = (
    productId: string,
    products,
    skipBillingAddressStep = false,
    country?: string,
    skiptoSecussStep = false,
  ): void => {
    const product = products.find(product => product.id === productId)

    const variants =
      product.variants.filter(variant =>
        variant.shippingCountries.includes(country),
      ) || []
    if (!product?.id) return null

    switch (step) {
      case Steps.NO_DIALOG: {
        /** select shipping country */
        if (product?.webshop?.shippingCountries?.length > 0) {
          setStep(Steps.SHIPPING_COUNTRIES)
        } else if (variants.length > 1) {
          /** select variant */
          setStep(Steps.PRODUCT_VARIANTS)
        } else {
          /** payments steps */
          // setStep(Steps.SHIPPING_ADDRESS)
          setStep(Steps.PAYMENT_METHODE)
        }
        break
      }
      case Steps.SHIPPING_COUNTRIES: {
        /** select variant */
        if (variants.length > 1) {
          setStep(Steps.PRODUCT_VARIANTS)
        } else {
          /** select payment methode */
          setStep(Steps.PAYMENT_METHODE)
        }
        break
      }
      case Steps.SHIPPING_ADDRESS: {
        if (skipBillingAddressStep) {
          setStep(Steps.PAYMENT)
        } else {
          setStep(Steps.BILLING_ADDRESS)
        }
        break
      }
      case Steps.SUCCESS: {
        /** back to step 0 at the end */
        setStep(Steps.NO_DIALOG)
        break
      }
      case Steps.PRODUCT_VARIANTS: {
        /** select payment methode */
        setStep(Steps.PAYMENT_METHODE)
        break
      }
      case Steps.PAYMENT_METHODE: {
        /** select payment methode */
        if (skiptoSecussStep) {
          setStep(Steps.SUCCESS)
        } else {
          setStep(Steps.SHIPPING_ADDRESS)
        }
        break
      }
      default: {
        /** go to next step */
        setStep(step + 1)
      }
    }
  }

  const goToPreviousStep = (
    productId: string,
    products,
    country?: string,
  ): void => {
    const product = products.find(product => product.id === productId)
    const variants =
      product.variants.filter(variant =>
        variant.shippingCountries.includes(country),
      ) || []

    if (!product?.id) return null

    switch (step) {
      case 2: {
        if (product?.webshop?.shippingCountries?.length > 0) {
          setStep(1)
        } else {
          setStep(0)
        }
        break
      }
      case 3:
        setStep(2.1)
        break
      case 2.1: {
        if (variants?.length > 1) {
          /** select variant */
          setStep(2)
        } else if (product?.webshop?.shippingCountries?.length > 0) {
          setStep(1)
        } else {
          setStep(0)
        }
        break
      }
      case 0: {
        setStep(0)
        break
      }
      default: {
        /** go to next step */
        setStep(step - 1)
      }
    }
  }
  const jumpTo = (step: number): void => {
    setStep(step)
  }
  return [step, goToNextStep, goToPreviousStep, resetStep, jumpTo] as any
}
