import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

type Props = {
  shopUrl: string
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '40px 20px',
  },
  text: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 10,
    lineHeight: '17px',
    opacity: 0.3,
    color: '#000000',
  },
})

export function Footer({ shopUrl }: Props) {
  const classes = useStyles()
  const supportEmail = 'support@myshop.io'
  const supportSubject = 'MyShop Query'
  return (
    <div aria-label="avatar" className={classes.root}>
      <span className={classes.text}>COPYRIGHT 2016-20 BRANDBASSADOR AS</span>
      <span className={classes.text}>ALL RIGHT RESERVED</span>
      <span className={classes.text}>DESIGNED IN OSLO, NORWAY</span>
      <br />
      <Link to={`/${shopUrl}/privacy_policy`} className={classes.text}>
        PRIVACY POLICY
      </Link>
      <a
        href={`mailto:${supportEmail}?subject=${supportSubject}`}
        className={classes.text}
      >
        CONTACT SUPPORT
      </a>
    </div>
  )
}

export default Footer
