import React from 'react'
import GoogleAnalytics from '../lib/GoogleAnalytics'

export function useHandleOpenDialog(setCurrentProductId) {
  const [openDialog, setOpenDialog] = React.useState(false)

  const handleOpenDialog = (productId: string): void => {
    GoogleAnalytics.track({
      category: 'Quantity',
      action: 'Open Quantity Dialog',
    })
    setCurrentProductId(productId)
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    GoogleAnalytics.track({
      category: 'Quantity',
      action: 'Close Quantity Dialog',
    })
    setCurrentProductId(null)
    setOpenDialog(false)
  }
  return [openDialog, handleOpenDialog, handleCloseDialog] as Array<any>
}
