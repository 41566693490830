import { gql } from 'apollo-boost'

const ConfirmPayment = gql`
  mutation confirmPayment($input: PaymentIntentInput!) {
    confirmPayment(input: $input) {
      paymentIntent {
        id
        status
        amount
        client_secret
        next_action {
          type
        }
      }
      orderName
      errors {
        message
        code
      }
    }
  }
`

export default ConfirmPayment
