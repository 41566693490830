import React from 'react'
import { Route, Switch } from 'react-router-dom'
// Route imports
import ShopPage from './Shop/index'
import GetYourOwnStore from './GetYourOwnStore'
import Delivery from './Delivery'
import PrivatePolicy from './PrivatePolicy'
import TermsAndConditions from './TermsAndConditions'
import Faq from './Faq'
import History from './History/history'
import Answer from './Faq/Answer'
import Error404 from './Errors/404'
/**
 * Rooter
 * */
function Root() {
  return (
    <Switch>
      <Route path="/get_your_own_store" component={GetYourOwnStore} />
      <Route path="/faq/:id" exact component={Answer} />
      <Route path="/faq" exact component={Faq} />
      <Route path="/delivery" exact component={Delivery} />
      <Route path="/privacy_policy" exact component={PrivatePolicy} />

      <Route path="/:shopUrl/get_your_own_store" component={GetYourOwnStore} />
      <Route path="/:shopUrl/faq" component={Faq} />
      <Route path="/:shopUrl/delivery" component={Delivery} />
      <Route path="/:shopUrl/privacy_policy" component={PrivatePolicy} />
      <Route path="/:shopUrl/history" component={History} />
      <Route
        path="/:shopUrl/terms_and_conditions"
        component={TermsAndConditions}
      />
      <Route path="/:shopUrl" component={ShopPage} />
      <Route path="/" component={() => <p>hello</p>} />

      <Route path="/Error/404" exact component={Error404} />
    </Switch>
  )
}

export default Root
