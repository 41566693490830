import { $$, subtractPercent } from 'moneysafe/ledger'
import { $, multiply, add } from 'moneysafe'

export const getTotalPrice = (
  price,
  discountPercentage = 0,
  quantity = 1,
  shippingFlatRate,
): number => {
  if (!price || price < 0 || discountPercentage < 0 || quantity < 0) return 0

  const variantPriceAfterDiscount = $$(
    $(price),
    subtractPercent(discountPercentage),
  ).toNumber()

  if (shippingFlatRate > 0) {
    const withShippingFlatRate = add(
      $(variantPriceAfterDiscount),
      $(shippingFlatRate),
    ).toNumber()
    return multiply($(withShippingFlatRate), quantity).toNumber()
  } else {
    return multiply($(variantPriceAfterDiscount), quantity).toNumber()
  }
}

export const getTotalPriceInCents = (
  price: number,
  discountPercentage = 0,
  quantity = 1,
  shippingFlatRate: number,
): number => {
  return multiply(
    $(getTotalPrice(price, discountPercentage, quantity, shippingFlatRate)),
    100,
  ).toNumber()
}
