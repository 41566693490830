import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

type Props = {
  /** url to the user images  */
  logo: string
  /** diameter of the avatar */
  diameter: number
  className?: string
  /** if true, is div background css to display the image, otherwise use <img /> */
  isBackground?: boolean
  /** should be a big avatar 90 in sm and 150 above */
  big?: boolean
}

const useStyles = makeStyles((theme: any) => ({
  outerCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: (props: Props) => props.diameter,
    height: (props: Props) => props.diameter,
    background: '#FFFFFF',
    borderRadius: '50%',
    margin: '10px 0',
    border: (props: Props) =>
      props.isBackground
        ? '1.5px solid #CACACA'
        : '1px solid rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('sm')]: {
      width: (props: Props) => (props.big ? 105 : props.diameter),
      height: (props: Props) => (props.big ? 105 : props.diameter),
    },
  },
  innerCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: (props: Props) => props.diameter - 8,
    height: (props: Props) => props.diameter - 8,
    backgroundImage: (props: Props) => `url('${props.logo}')`,
    backgroundSize: 'cover',
    borderRadius: '50%',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#11222E',

    [theme.breakpoints.up('sm')]: {
      width: (props: Props) => (props.big ? 97 : props.diameter - 8),
      height: (props: Props) => (props.big ? 97 : props.diameter - 8),
    },
  },
  image: {
    height: 'auto',
    maxWidth: 25,
    maxHeight: 20,
    'object-fit': 'contain',
    filter:
      'invert(50%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(0) contrast(100%)',
  },
}))
const defaultLogo = '/static/images/default-image.png'

const isUrl = (str: string) => str.includes('https')

const getUrl = (logo: string) => {
  return isUrl(logo) ? logo : `${process.env.REACT_APP_API}/images/${logo}`
}

export function Avatar({
  logo,
  diameter = 60,
  className = '',
  isBackground = false,
  big = false,
}: Props) {
  const src = logo ? getUrl(logo) : defaultLogo

  const classes = useStyles({ logo: src, diameter, isBackground, big })
  return (
    <div aria-label="avatar" className={cn(classes.outerCircle, className)}>
      {isBackground ? (
        <div className={cn(classes.innerCircle, className)} />
      ) : (
        <img alt="avatar" className={classes.image} src={src} />
      )}
    </div>
  )
}

export default Avatar
