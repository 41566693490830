/* eslint-disable @typescript-eslint/camelcase */
import { Country, Currency } from '../types'
import { getCountryName } from './getCountryData'
import { getTotalPriceInCents } from './getTotalPrice'
/**
 * take an error code and return the message
 * that should be displayed to the user
 * */
export function errorMessageFromCode(code: string, message: string): string {
  switch (code) {
    case 'ORDER_CREATION_ERROR':
    case 'ORDER_CONFIRMATION_ERROR':
    case 'PRICE_ERROR':
      return "Error on putting the order on the brand's webshop."
    case 'PAYMENT_ERROR':
      return message || 'Error on processing the payment.'
    default:
      return 'Something went wrong.'
  }
}

/** check if a country is in the shipping countries array */
export const IPLocationInShippingCounties = (
  ipLocation,
  shippingCountries,
): boolean => {
  if (!ipLocation || !shippingCountries || !shippingCountries.length) {
    return false
  }
  return !!shippingCountries.find(country => country === ipLocation)
}

/* Check if exist in shipping countries array */
export const isCountryInShippingCountries = (
  country: Country,
  shippingCountries: Array<string>,
): boolean => {
  if (
    country &&
    country.code &&
    shippingCountries &&
    shippingCountries.length > 0
  ) {
    return !!shippingCountries.find(c => c === country.code)
  }
  return false
}

export const sortCountries = (country1: Country, country2: Country) => {
  if (country1.name < country2.name) return -1
  if (country1.name > country2.name) return 1
  return 0
}

export const getPriceFormatted = (
  price: number,
  currency: Currency,
  rate: number,
) => {
  if (rate === 1 || rate <= 0) return `$${price.toFixed(2)}`
  if (!currency) return `$${price.toFixed(2)}`
  if (!price) return ``

  const newPrice = price * rate
  return `${currency?.symbolNative}${newPrice.toFixed(2) || ''}`
}

export const getYoutubeUrlId = (youtubeUrl?: string): string | null => {
  if (!youtubeUrl || youtubeUrl.length <= 0) return null
  // check if valid youtube url then return the id
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
  const match = youtubeUrl.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  } else {
    return null
  }
}

export const getPriceWithShippingFlatRateFormatted = (
  initialPrice: number,
  currency: Currency,
  rate: number,
  shippingFlatRate: number,
) => {
  const price = initialPrice + shippingFlatRate
  if (rate === 1 || rate <= 0) return `$${price}`
  if (!currency) return `$${price}`
  if (!price) return ``

  const newPrice = price * rate
  return `${currency?.symbolNative}${newPrice.toFixed(2) || ''}`
}

export const normalizeUrl = url => {
  if (!/^https?:\/\//i.test(url)) {
    return 'http://' + url
  }
  return url
}
export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
export const normalizeShortUrl = (brandName, website): string => {
  try {
    return capitalize(
      new URL(normalizeUrl(website)).hostname.replace('www.', ''),
    )
  } catch (error) {
    console.log('website', website)
    console.log(error)
    return brandName
  }
}

export const getCreatePaymentIntentInput = (
  product,
  orderDetails,
  quantity,
  paymentMethodId,
  shippingFlatRate,
) => {
  const {
    variant,
    shippingDetails,
    billingDetails,
    buyerAcceptsMarketing,
  } = orderDetails

  const total = getTotalPriceInCents(
    variant.price,
    variant.discountPercentage,
    quantity,
    shippingFlatRate,
  )

  return {
    paymentDetails: {
      paymentMethodId: paymentMethodId,
      currency: 'usd',
      amount: total,
    },
    orderDetails: {
      productId: product.id,
      variant_id: variant.variantId,
      quantity: quantity,
      email: billingDetails.email,
      shipping_address: {
        first_name: shippingDetails.firstName,
        last_name: shippingDetails.lastName,
        address1: shippingDetails.address,
        address2: shippingDetails.address2 || '',
        city: shippingDetails.city,
        province: shippingDetails.province || '',
        country: getCountryName(shippingDetails.country),
        zip: shippingDetails.postCode,
        country_code: shippingDetails.country,
        phone: shippingDetails.phone || billingDetails.phone || '',
      },
      billing_address: {
        first_name: billingDetails.firstName,
        last_name: billingDetails.lastName,
        address1: billingDetails.address,
        address2: billingDetails.address2 || '',
        city: billingDetails.city,
        province: billingDetails.province || '',
        country: getCountryName(billingDetails.country),
        zip: billingDetails.postCode,
        country_code: billingDetails.country,
        phone: shippingDetails.phone || billingDetails.phone || '',
      },
      buyer_accepts_marketing: buyerAcceptsMarketing,
    },
  }
}
