import { useQuery } from '@apollo/react-hooks'
import { GetCurrencyExchangeRate } from './graphql/GetCurrencyExchangeRate.graphql'

type CurrencyExchangeRateInput = {
  amount?: number
  from?: string
  to: string
}
export function useGetCurrencyExchangeRate({
  amount = 1,
  from = 'USD',
  to,
}: CurrencyExchangeRateInput) {
  const { data, loading, error } = useQuery(GetCurrencyExchangeRate, {
    variables: {
      input: {
        amount,
        from,
        to,
      },
    },
  })

  const rate = data?.getCurrencyExchangeRate?.amount
  return { rate, loading, error }
}

export default useGetCurrencyExchangeRate
