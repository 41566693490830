import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Formik } from 'formik'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import ReactCountryFlag from 'react-country-flag'
import { object, string } from 'yup'

import Title from '../Title/Title'
import DialogContainer from '../DialogContainer/DialogContainer'
import { getAllCountries, getCountryByCode } from '../../lib/getCountryData'
import { getCurrenciesByCountryCode } from '../../lib/getCurrencyData'
import { Country, Currency } from '../../types'
import clsx from 'clsx'

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    color: '#000',
    marginTop: 30,
    marginBottom: 5,
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontSize: 12,
    lineHeight: '16px',
    color: '#999999',
    letterSpacing: '-0.02em',
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 25,
    paddingBottom: 30,
    paddingTop: 10,
  },
  input: {
    marginBottom: 15,
    marginTop: 5,
    minWidth: 250,
  },
  rootStyle: {
    height: 50,
    borderRadius: 10,
    background: '#9a9a9a08',
  },
  labelStyle: {
    top: -1,
    fontFamily: 'Nunito',
    fontSize: 16,
    letterSpacing: '-0.02em',
    color: '#999999',
  },
  item: {
    height: 30,
  },
  selectStyle: {
    '&::focus': {
      borderRadius: 10,
    },
  },
  button: {
    ...theme.styles.button,
    height: 50,
    marginTop: 5,
    ...theme.styles.blueButton,
  },
  checkBox: {
    width: '23.02px',
    height: '24px',
    border: '1px solid #DFDEDE',
    boxSizing: 'border-box',
    borderRadius: '3px',
  },
  checkBoxRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkBoxIcon: {
    borderRadius: 3,
    width: 23,
    height: 24,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkBoxCheckedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 23,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}))

type Props = {
  /** list of currencies */
  // currencies?: Array<Currency>
  /** on Select currency */
  onSelect: ({
    country,
    currency,
    shipToSelectedCountry,
  }: {
    country?: Country
    currency?: Currency
    shipToSelectedCountry: boolean
  }) => void
  /** default selected country code */
  defaultCountryCode?: string
  /** callback when the dialog close */
  onClose: (...args: any[]) => void
  /** is the dialog open? */
  open: boolean
  shipToSelectedCountry: boolean
}

const validationSchema = object({
  country: string().required('Required'),
})

export function SelectCountryCurrencyDialog({
  onSelect,
  defaultCountryCode = '',
  onClose,
  open,
  shipToSelectedCountry,
}: Props) {
  const classes = useStyles()
  const countries = getAllCountries()

  const renderMenuItem = (country: Country) => {
    const currencies = getCurrenciesByCountryCode(country.code)

    if (currencies && currencies[0]) {
      const currency = currencies[0]
      return (
        <MenuItem
          key={country.code}
          value={country.code}
          className={classes.item}
          data-testid={country.code}
        >
          <ReactCountryFlag
            styleprops={{
              width: '20px',
              height: '20px',
            }}
            countryCode={country.code}
            svg
          />
          &nbsp; &nbsp;
          {country.name} ({currency.code})
        </MenuItem>
      )
    }
    return null
  }

  const handleSubmit = values => {
    if (values && values.country) {
      const country = getCountryByCode(values.country)
      const currencies = getCurrenciesByCountryCode(values.country)
      let currency
      if (currencies && currencies[0]) {
        currency = currencies[0]
      }
      onSelect({
        country,
        currency,
        shipToSelectedCountry: values.shipToSelectedCountry,
      })
    }
  }

  return (
    <DialogContainer open={open} onClose={onClose}>
      <div className={classes.root}>
        <Title title="Shipping & Currency" onClose={onClose} />
        <span className={classes.subTitle}>
          Select country & currency (Transactions in USD)
        </span>

        <Formik
          initialValues={{ country: defaultCountryCode, shipToSelectedCountry }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            errors,
          }: any) => (
            <div className={classes.container}>
              <TextField
                id="country-currency-select"
                classes={{ root: classes.rootStyle }}
                className={classes.input}
                variant="outlined"
                label="Country"
                name="country"
                InputProps={{ classes: { root: classes.rootStyle } }}
                InputLabelProps={{ classes: { root: classes.labelStyle } }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.country}
                error={touched.country && !!errors.country}
                select
                SelectProps={{ classes: { root: classes.selectStyle } }}
              >
                {countries.map(renderMenuItem)}
              </TextField>
              <FormControlLabel
                classes={{
                  root: classes.labelStyle,
                }}
                control={
                  <Checkbox
                    name="shipToSelectedCountry"
                    checked={values.shipToSelectedCountry}
                    disableRipple={true}
                    checkedIcon={
                      <span
                        className={clsx(
                          classes.checkBoxIcon,
                          classes.checkBoxCheckedIcon,
                        )}
                      />
                    }
                    icon={<span className={classes.checkBoxIcon} />}
                    color={'primary'}
                    classes={{
                      root: classes.checkBoxRoot,
                    }}
                    onChange={() =>
                      setFieldValue(
                        'shipToSelectedCountry',
                        !values.shipToSelectedCountry,
                      )
                    }
                  />
                }
                label={
                  <div style={{ marginLeft: '10px' }}>
                    Show items that do not ship to the selected country
                  </div>
                }
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleSubmit}
                data-testid="country-currency-ok"
              >
                Ok
              </Button>
            </div>
          )}
        </Formik>
      </div>
    </DialogContainer>
  )
}

export default SelectCountryCurrencyDialog
