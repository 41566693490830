import { gql } from 'apollo-boost'

const LikeProduct = gql`
  mutation likeProduct($input: LikeInput!) {
    likeProduct(input: $input) {
      id
      likes
    }
  }
`

export default LikeProduct
