/* eslint-disable  @typescript-eslint/camelcase */
import React, { useCallback, useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import cn from 'classnames'
import usePaymentHandler from '../../hooks/usePaymentHandler'
import { Address, Variant, Product } from '../../types'
import { PaymentStatus, stripeStatus } from '../../Constants'
import {
  errorMessageFromCode,
  getCreatePaymentIntentInput,
} from '../../lib/utils'
import moment from 'moment'
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'

type Props = {
  orderDetails: {
    variant: Variant
    shippingDetails: Address
    billingDetails: Address
  }
  product: Product
  quantity: number
  onSuccess?: (args: any) => void
  setOrderId: (arg: string) => void
  onAddToOrdersHistory: (...args: any[]) => void
  shippingFlatRate?: number
  shippingCountry?: string
  onErrorMessage?: (args: any) => void
  onLoaderStatus?: (args: any) => void
  onStatus?: (args: any) => void
}

const useStyles = makeStyles(
  (theme: any) => ({
    padding: {
      paddingRight: 25,
      paddingLeft: 25,
      paddingTop: 7,
      paddingBottom: 28,
      width: '100%',
    },
    cardButton: {
      ...theme.styles.button,
      height: 50,
      padding: 0,
      width: '-webkit-fill-available',
      color: '#FBFBFB!important',
      textTransform: 'none!important',
      marginTop: 23,
    },
    applePay: {
      marginBottom: 8,
      ...theme.styles.blackButton,
    },
    googlePay: {
      marginBottom: 8,
      ...theme.styles.grayButton,
    },
    note: {
      fontFamily: 'Nunito',
      fontSize: 12,
      lineHeight: '16px',
      color: '#999999',
      letterSpacing: '-0.02em',
      marginBottom: 16,
      textAlign: 'center',
      paddingLeft: 4,
      paddingRight: 8,
    },
    circularProgress: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
    },
  }),
  { index: 1 },
)

export function PaymentRequestWrapper({
  orderDetails,
  product,
  quantity,
  onSuccess,
  setOrderId,
  onAddToOrdersHistory,
  shippingFlatRate,
  shippingCountry,
  onErrorMessage,
  onLoaderStatus,
  onStatus,
}: Props) {
  const classes = useStyles()
  const [paymentHandler] = usePaymentHandler(setOrderId, onLoaderStatus)
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [paymentOption, setPaymentOption] = useState(null)
  const [loading, setLoading] = useState(true)

  const stripe = useStripe()

  const handlePayment = useCallback(
    async input => {
      const { status, orderName } = await paymentHandler(input, stripe)

      if (status === stripeStatus.SUCCEEDED) {
        onStatus(PaymentStatus.SUCCESS)
        onAddToOrdersHistory({
          orderId: orderName,
          date: moment().format('D MMM YYYY / k:mm'),
          price: product.myShopPrice + shippingFlatRate,
          photo: product.photos[0],
          website: product.brand.website,
          supportEmail: product.variants[0]?.supportEmail,
          deliveryTime: product.webshop?.deliveryTime,
          privacyPolicyUrl: product.webshop?.privacyPolicyUrl,
          termsAndConditionsUrl: product.webshop?.termsAndConditionsUrl,
          brandName: product.brand?.name,
          returnPolicy: product.webshop?.returnPolicy,
        })
        onSuccess(true)
      } else {
        throw new Error('PAYMENT_NOT_SUCCESSFUL')
      }
    },
    [
      onAddToOrdersHistory,
      onStatus,
      onSuccess,
      paymentHandler,
      product.brand,
      product.myShopPrice,
      product.photos,
      product.variants,
      product.webshop,
      shippingFlatRate,
      stripe,
    ],
  )

  useEffect(() => {
    if (stripe) {
      const draft = getCreatePaymentIntentInput(
        product,
        orderDetails,
        quantity,
        null,
        shippingFlatRate,
      )
      try {
        const pr = stripe.paymentRequest({
          country: shippingCountry,
          currency: draft.paymentDetails.currency,
          total: {
            label: product.name,
            amount: draft.paymentDetails.amount,
          },
          requestPayerName: true,
          requestPayerEmail: true,
          requestShipping: true,
          shippingOptions: [
            {
              id: 'free-shipping',
              label: 'Free shipping',
              detail: `Average delivery time ${product?.webshop?.deliveryTime ||
                '2 - 5'} days`,
              amount: 0,
            },
          ],
        })

        // Check the availability of the Payment Request API.
        pr.canMakePayment()
          .then(result => {
            if (result && !paymentRequest) {
              setPaymentOption(result)
              setPaymentRequest(pr)
            }
            if (loading) {
              setLoading(false)
            }
          })
          .catch(error => {
            console.error(error)
            if (loading) {
              setLoading(false)
            }
          })

        pr.on('paymentmethod', async ev => {
          try {
            const orderDetailsWrapper = {
              ...orderDetails,
              shippingDetails: {
                ...ev.shippingAddress,
                address: ev.shippingAddress.addressLine
                  ? ev.shippingAddress.addressLine[0]
                  : '',
                address2: ev.shippingAddress.addressLine
                  ? ev.shippingAddress.addressLine[1] || ''
                  : '',
                postCode: ev.shippingAddress.postalCode,
                firstName: ev.shippingAddress.recipient
                  .split(' ')
                  .slice(0, -1)
                  .join(' '),
                lastName: ev.shippingAddress.recipient
                  .split(' ')
                  .slice(-1)
                  .join(' '),
              },
              billingDetails: {
                ...ev.paymentMethod.billing_details,
                ...ev.paymentMethod.billing_details.address,
                address: ev.paymentMethod.billing_details.address.line1,
                address2: ev.paymentMethod.billing_details.address.line2 || '',
                postCode: ev.paymentMethod.billing_details.address.postal_code,
                firstName: ev.paymentMethod.billing_details.name
                  .split(' ')
                  .slice(0, -1)
                  .join(' '),
                lastName: ev.paymentMethod.billing_details.name
                  .split(' ')
                  .slice(-1)
                  .join(' '),
              },
            }

            onStatus(PaymentStatus.LOADING)

            const input = getCreatePaymentIntentInput(
              product,
              orderDetailsWrapper,
              quantity,
              ev.paymentMethod.id,
              shippingFlatRate,
            )

            await handlePayment(input)

            ev.complete('success')
          } catch (error) {
            console.error(error.code, error.message)
            onErrorMessage(errorMessageFromCode(error.code, error.message))
            onStatus(PaymentStatus.ERROR)
            ev.complete('fail')
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }, [
    handlePayment,
    loading,
    onErrorMessage,
    onStatus,
    orderDetails,
    paymentRequest,
    product,
    quantity,
    shippingCountry,
    shippingFlatRate,
    stripe,
  ])

  if (loading) {
    return (
      <Box className={classes.circularProgress}>
        <CircularProgress />
      </Box>
    )
  }

  if (!paymentOption) {
    return (
      <Typography className={classes.note}>
        Save a card in Chrome, or add a card to your Wallet for Safari to use
        Apple Pay or Google Pay
      </Typography>
    )
  }
  if (paymentOption.applePay) {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={cn(classes.cardButton, classes.applePay)}
        onClick={() => paymentRequest.show()}
        disableElevation
      >
        <img alt="stripe logo" src="/static/images/stripe-apple-pay.svg" />
      </Button>
    )
  }
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      className={cn(classes.cardButton, classes.googlePay)}
      onClick={() => paymentRequest.show()}
      disableElevation
    >
      <img alt="stripe logo" src="/static/images/stripe-google-pay.svg" />
    </Button>
  )
}

export default PaymentRequestWrapper
