import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Avatar, Box, LinearProgress } from '@material-ui/core'
import cn from 'classnames'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
const useStyles = makeStyles((theme: any) => ({
  appBar: {
    display: 'block',
    margin: 0,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(1.5),
    },
  },
  fullWidth: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 3,
      overflow: 'hidden',
      width: 'calc(100vmin * 0.50)',
      margin: '22.5px auto',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 'bold',
    fontSize: 13,
  },
  indicators: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: theme.spacing(1),
    overflow: 'hidden',
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(1.5),
    },
  },
  indicator: {
    marginRight: 3,
    marginLeft: 3,
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    height: 2,
    maxHeight: 2,
    borderRadius: 2,
    overflow: 'hidden',
  },
  activeIndicator: {
    backgroundColor: 'rgba(0, 0, 0, 0.49)',
    height: 20,
    borderRadius: 2,
    marginRight: 0,
    marginLeft: 0,
  },
  colorPrimary: {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
  },
  barColorPrimary: {
    backgroundColor: 'rgba(0, 0, 0, 0.49)',
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  caption: {
    color: theme.palette.gray,
    marginBottom: theme.spacing(5),
    maxWidth: '75%',
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.white,
    userSelect: 'none',
  },
  badge: {
    backgroundColor: theme.palette.white,
    opacity: 0.85,
    border: '1px solid rgba(140, 140, 140, 0.3)',
    boxSizing: 'border-box',
    boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.07)',
    width: 203,
    minWidth: 203,
    maxWidth: 203,
    height: 203,
    borderRadius: '100%',
    marginBottom: theme.spacing(3),

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '17vmin',
  },

  swiperContainer: {
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    zIndex: 1,
    background: theme.palette.white,
    position: 'relative',
    width: '100%',
    pointerEvents: 'none',
  },
  swiper: {
    position: 'relative',
    minHeight: 'calc(100% - 58px)',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100% - 64px)',
    },
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
    pointerEvents: 'none',
  },
  container: {
    margin: 'auto',
    height: '100vh',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 3,
      overflow: 'hidden',
      width: 'calc(100vmin * 0.50)',
      height: 'calc(100vmin - 45px)',
      background: 'blue',
    },
  },
  '@global': {
    '.swiper-button-prev': {
      color: '#444!important',
      display: 'none!important',
    },
    '.swiper-button-next': {
      color: '#444!important',
      display: 'none!important',
    },
    [theme.breakpoints.up('sm')]: {
      '.swiper-button-next, .swiper-button-prev': {
        display: 'flex!important',
      },
    },
  },
}))

export default function FullScreenBadge(props: {
  index: any
  achievements: any
  open: any
  handleClose: any
  logo: string
  shopName: string
}) {
  const classes = useStyles()

  const [pause, setPause] = useState(false)
  const [pauseStartTime, setPauseStartTime] = useState(0)

  const swiperRef = useRef(null)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(props.index)

  const step = 1
  const interval = 40
  const maxProgress = 100

  const handleCloseWrapper = useCallback(() => {
    setCurrentIndex(0)
    setProgressPercentage(0)
    props.handleClose()
  }, [props])

  const handlePreviousOrNext = useCallback(
    (event: any) => {
      const realIndex =
        (swiperRef &&
          swiperRef.current &&
          swiperRef.current.swiper.realIndex) ||
        0
      if (event.nativeEvent.offsetX < event.currentTarget.offsetWidth / 2) {
        if (realIndex > 0) {
          setProgressPercentage(0)
          setCurrentIndex(
            ((swiperRef &&
              swiperRef.current &&
              swiperRef.current.swiper.realIndex) ||
              0) - 1,
          )
        }
      } else {
        if (realIndex + 1 < (props?.achievements?.length || 0)) {
          setProgressPercentage(0)
          setCurrentIndex(
            ((swiperRef &&
              swiperRef.current &&
              swiperRef.current.swiper.realIndex) ||
              0) + 1,
          )
        } else if (realIndex + 1 === props?.achievements?.length) {
          handleCloseWrapper()
        }
      }
    },
    [handleCloseWrapper, props],
  )

  const handlePause = useCallback(() => {
    setPause(true)
    setPauseStartTime(new Date().getTime())
  }, [])
  const handleResume = useCallback(
    (event: any, isMobile = false) => {
      if (!isMobile && new Date().getTime() - pauseStartTime < 1000) {
        handlePreviousOrNext(event)
      }
      setPause(false)
    },
    [handlePreviousOrNext, pauseStartTime],
  )

  useEffect(() => {
    let timeout
    const updateProgress = (): void => {
      if (pause) return
      if (progressPercentage + step >= 100) {
        if (
          props.achievements &&
          currentIndex === props.achievements.length - 1 &&
          props.handleClose
        ) {
          handleCloseWrapper()
        }
        swiperRef && swiperRef.current && swiperRef.current.swiper.slideNext()
      } else {
        setProgressPercentage(progressPercentage + step)
      }
    }
    if (progressPercentage < maxProgress && currentIndex > -1) {
      timeout = setTimeout(updateProgress, interval)
    }
    return (): void => {
      clearTimeout(timeout)
    }
  }, [
    maxProgress,
    progressPercentage,
    currentIndex,
    props,
    handleCloseWrapper,
    pause,
  ])

  const params = {
    centeredSlides: true,
    preventClicks: false,
    preventClicksPropagation: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      slideChangeTransitionStart: (): void => {
        setProgressPercentage(0)
      },
      slideChange: (): void => {
        setCurrentIndex(
          swiperRef && swiperRef.current && swiperRef.current.swiper.realIndex,
        )
      },
      init: (): void => {
        setCurrentIndex(props.index)
        setProgressPercentage(0)
      },
    },
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleCloseWrapper}
      PaperProps={{
        style: {
          backgroundColor: '#262626',
          boxShadow: 'none',
        },
      }}
    >
      <Box className={classes.container}>
        <AppBar className={classes.appBar} color="transparent" elevation={0}>
          <Toolbar>
            <div className={classes.fullWidth}>
              <div className={classes.flex}>
                <Avatar alt={props.shopName} src={props.logo} />
                <Typography variant="h6" className={classes.title}>
                  {props.shopName}
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseWrapper}
                  aria-label="close"
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.indicators}>
                {props.achievements.map((achievement, index) => (
                  <div className={cn(classes.indicator)} key={index}>
                    {currentIndex === index ? (
                      <LinearProgress
                        variant="determinate"
                        classes={{
                          colorPrimary: classes.colorPrimary,
                          barColorPrimary: classes.barColorPrimary,
                        }}
                        value={progressPercentage}
                      />
                    ) : currentIndex > index ? (
                      <LinearProgress
                        variant="determinate"
                        classes={{
                          colorPrimary: classes.barColorPrimary,
                          barColorPrimary: classes.transparent,
                        }}
                        value={100}
                      />
                    ) : (
                      <LinearProgress
                        variant="determinate"
                        classes={{
                          colorPrimary: classes.colorPrimary,
                          barColorPrimary: classes.transparent,
                        }}
                        value={0}
                      />
                    )}
                    <LinearProgress
                      variant="determinate"
                      classes={{
                        colorPrimary: classes.colorPrimary,
                        barColorPrimary: classes.barColorPrimary,
                      }}
                      value={
                        currentIndex === index
                          ? progressPercentage
                          : currentIndex > index
                          ? 100
                          : 0
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div
          className={classes.wrapper}
          {...{
            onTouchStart: handlePause,
            onMouseDown: handlePause,
            onMouseUp: handleResume,
            onTouchEnd: (event: any) => handleResume(event, true),
            onMouseLeave: handleResume,
          }}
        >
          <Swiper
            shouldSwiperUpdate
            wrapperClass={classes.swiper}
            containerClass={classes.swiperContainer}
            activeSlideKey={`${currentIndex}`}
            ref={swiperRef}
            {...params}
          >
            {props.achievements.map((achievement, index) => (
              <div className={classes.wrapper} key={index}>
                <div className={classes.badge}>{achievement.emoji}</div>
                <Typography variant="subtitle1">{achievement.title}</Typography>
                <Typography variant="caption" className={classes.caption}>
                  {achievement.description}
                </Typography>
              </div>
            ))}
          </Swiper>
        </div>
      </Box>
    </Dialog>
  )
}
