import { useQuery } from '@apollo/react-hooks'
import {
  GetShopByUrl,
  GetShopProductsByUrl,
} from './graphql/GetShopByUrl.graphql'
import { getOr, constant, last, updateWith } from 'lodash/fp'

const LIMIT = 30

export function useGetShopByUrl(url) {
  const { data, loading, error } = useQuery(GetShopByUrl, {
    variables: { url },
  })

  const shop = getOr(null, 'shopByUrl', data)
  return { shop, loading, error }
}

export function useGetShopProductsByUrl(
  url,
  countryCode = 'AL',
  countAsView = false,
  limit = LIMIT,
) {
  const { data, loading, error, fetchMore } = useQuery(GetShopProductsByUrl, {
    fetchPolicy: 'cache-and-network',
    variables: { countryCode, url, countAsView, limit },
  })

  const shop = getOr(null, 'shopByUrl', data)

  const loadMore = (
    offset: number,
    limit: number = LIMIT,
    countAsView = true,
  ) => {
    fetchMore({
      variables: {
        countryCode,
        url,
        offset,
        limit,
        countAsView,
      },
      query: GetShopProductsByUrl,
      updateQuery: (previousResult, { fetchMoreResult }: any) => {
        const path = ['shopByUrl', 'productConnection', 'edges']
        const oldEdges = getOr([], path, previousResult)
        const newEdges = getOr([], path, fetchMoreResult)
        const allEdges = oldEdges.concat(newEdges)
        const newResult = { ...fetchMoreResult }

        return newEdges.length &&
          getOr(null, ['cursor'], last(newEdges)) !==
            getOr(null, ['cursor'], last(oldEdges))
          ? updateWith(newResult, path, constant(allEdges), newResult)
          : previousResult
      },
    })
  }
  return { shop, loading, error, loadMore }
}

export default useGetShopByUrl
