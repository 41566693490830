import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'

import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton/IconButton'

type Props = {
  title: string
  className?: string
  onPrevious?: (args: any) => void
  onClose: (args: any) => void
}

const useStyles = makeStyles({
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: '30px 15px 5px 15px',
    color: '#000',
  },
  menuIcon: {
    fontSize: '2rem',
    width: 27,
    height: 27,
    color: 'rgba(0,0,0,0.2)!important',
  },
  flex: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 5%',
  },
  empty: {
    width: 40,
  },
})

export function Title({ title, className, onPrevious, onClose }: Props) {
  const classes = useStyles()
  return (
    <div className={classes.flex}>
      {(onPrevious && (
        <IconButton
          onClick={onPrevious}
          edge="end"
          color="inherit"
          aria-label="menu"
          data-testid="arrow-back"
        >
          <ArrowBackIcon />
        </IconButton>
      )) || <div className={classes.empty}></div>}
      <div className={cn(className, classes.title)}>{title}</div>
      <IconButton
        onClick={onClose}
        edge="end"
        color="inherit"
        aria-label="menu"
      >
        <CloseIcon className={classes.menuIcon} />
      </IconButton>
    </div>
  )
}

export default Title
