import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ReactCountryFlag from 'react-country-flag'
import ProductQuantity from '../ProductQuantity/ProductQuantity'
import { LikeHeart, LikeRedHeart } from './icons'
import IconButton from '@material-ui/core/IconButton/IconButton'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Link,
} from '@material-ui/core'

import ReturnPolicy from '../../ReturnPolicy'
import { getCountryName } from '../../../lib/getCountryData'
import { getPriceFormatted } from '../../../lib/utils'
import { Currency } from '../../../types'

type Props = {
  /** product name */
  name: string
  /** the price in MyShop */
  myPrice: number
  /** the price in WebShop */
  webShopPrice: number
  onOpenQuantityDialog: () => void
  quantity: number
  onBuy: () => void
  onLike: () => void
  /** does the user like the product */
  isLiked: boolean
  /** number of likes */
  likes: number
  description: string
  customDescription?: string | undefined
  brandName: string
  brandShortUrl: string
  returnPolicy: string
  warehouseCountries: Array<string>
  ipLocation: string
  deliveryTime: string
  /** Does the brand ship to the IP country ? */
  shipToIpLocation: boolean
  exchangeRate?: number
  currency?: Currency

  shipToSelectedCountry?: boolean
  selectedCountry?: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 15px 25px 15px',
    background: theme.palette.white,
  },
  name: {
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    fontSize: 17,
    lineHeight: '18px',
    marginBottom: 3,
    letterSpacing: '-0.2px',
  },
  myPrice: {
    position: 'relative',
    fontSize: 14,
    color: theme.palette.blue,
    display: 'inline-block',
    letterSpacing: '-0.2px',
  },
  webShopPrice: {
    position: 'relative',
    fontSize: 14,
    color: theme.palette.gray,
    letterSpacing: '-0.2px',
    display: 'inline-block',
    textDecoration: 'line-through',
    paddingLeft: 4,
  },
  currency: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 700,
    top: 2,
  },
  bold: {
    fontWeight: 800,
    fontSize: 16,
    letterSpacing: '-0.3px',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  actions: {
    display: 'flex',
    paddingTop: 15,
    zIndex: 10,
  },
  buyButton: {
    ...theme.styles.button,
    height: 45,
    padding: 0,
    width: '-webkit-fill-available',
    ...theme.styles.blueButton,
    color: '#FBFBFB!important',
    textTransform: 'none!important',
  },
  qtyButton: {
    width: 50,
    height: 45,
    marginRight: 3,
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  titleSection: {},
  likeSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 55,
    position: 'absolute',
    right: 5,
    top: 450,
    zIndex: 10,
  },
  likeText: {
    fontSize: 11,
    fontFamily: 'Helvetica',
    lineHeight: '13px',
    color: '#AAAAAA',
    paddingTop: 0,
  },
  infoSection: {
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  likeIcon: {
    padding: 5,
    margin: 0,
    height: 30,
    width: 30,
  },
  showDescription: {
    paddingLeft: 15,
    fontFamily: 'Helvetica',
    fontSize: 12,
    lineHeight: '17px',
    textDecorationLine: 'underline',
    color: '#AAAAAA',
    cursor: 'pointer',
    whiteSpace: 'pre-line',
  },
  rootAccordion: {
    boxShadow: 'unset',
    '&::before': {
      content: 'unset',
    },
  },
  rootAccordionSummary: {
    height: 0,
    minHeight: '0!important',
  },
  rootAccordionDetails: {
    padding: 0,
    display: 'block',
  },
  description: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    lineHeight: '17px',
    color: 'rgba(0, 0, 0, 0.5)!important',
  },
  listItem: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    lineHeight: '17px',
    color: 'rgba(0, 0, 0, 0.5)',
    padding: '3px 0',
  },
  list: {
    padding: '14px 0',
  },
  link: {
    textDecoration: 'underline',
    color: 'rgba(0, 0, 0, 0.5)!important',
  },
  listItemIcon: {
    width: 15,
    height: 15,
    marginBottom: -2.5,
    marginRight: 11,
  },
  smallIcon: {
    width: 13,
    height: 13,
  },
  marginBottom: {
    marginBottom: 5,
  },
  shippingLabel: {
    top: 459,
    display: 'flex',
    zIndex: 10,
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 400,
  },
}))

const ProductDescription = React.forwardRef(
  (
    {
      customDescription,
      description,
      brandName,
      returnPolicy,
      warehouseCountries,
      ipLocation,
      deliveryTime,
      shipToIpLocation,
    }: {
      customDescription?: string | undefined
      description: string
      brandName: string
      returnPolicy: string
      warehouseCountries: Array<string>
      ipLocation: string
      deliveryTime: string
      shipToIpLocation: boolean
    },
    ref: React.MutableRefObject<null>,
  ) => {
    const classes = useStyles()

    const [returnPolicyIsOpen, setReturnPolicyIsOpen] = useState(false)

    const toggleReturnPolicy = event => {
      event.preventDefault()
      setReturnPolicyIsOpen(!returnPolicyIsOpen)
    }

    const warehouseCountryNames = warehouseCountries
      ? warehouseCountries.map(code => getCountryName(code))
      : undefined

    return (
      <>
        <ReturnPolicy
          brandName={brandName}
          content={returnPolicy}
          open={returnPolicyIsOpen}
          onClose={toggleReturnPolicy}
        />
        <Accordion classes={{ root: classes.rootAccordion }}>
          <AccordionSummary
            ref={ref}
            classes={{ root: classes.rootAccordionSummary }}
          ></AccordionSummary>
          <AccordionDetails classes={{ root: classes.rootAccordionDetails }}>
            {customDescription && (
              <>
                <Typography className={classes.description}>
                  <b>My description:</b>
                </Typography>

                <Typography className={classes.description}>
                  {(customDescription || description)
                    .split('\n')
                    .map((p, i) => (
                      <span key={`${p}-${i}`}>
                        {p}
                        <br />
                      </span>
                    ))}
                </Typography>
              </>
            )}
            <div className={classes.list}>
              <Typography className={classes.listItem}>
                <img
                  className={classes.listItemIcon}
                  alt="stripe logo"
                  src="/static/icons/info.svg"
                />
                {shipToIpLocation ? 'Free shipping to' : 'Does not ship to'}{' '}
                <b>{ipLocation}</b>
              </Typography>

              {warehouseCountryNames && (
                <Typography className={classes.listItem}>
                  <img
                    className={classes.listItemIcon}
                    alt="location logo"
                    src="/static/icons/location.svg"
                  />
                  Ships from {brandName} in{' '}
                  <b>{warehouseCountryNames.join(' / ')}</b>
                </Typography>
              )}
              <Typography className={classes.listItem}>
                <img
                  className={classes.listItemIcon}
                  alt="delivery logo"
                  src="/static/icons/delivery.svg"
                />
                Average delivery time <b>{deliveryTime || '2 - 5'} days</b>
              </Typography>
              {returnPolicy && (
                <Typography className={classes.listItem}>
                  <img
                    className={cn(classes.listItemIcon, classes.smallIcon)}
                    alt="policy logo"
                    src="/static/icons/return-policy.svg"
                  />
                  <Link
                    className={classes.link}
                    href="#"
                    onClick={toggleReturnPolicy}
                  >
                    <b>Return Policy</b>
                  </Link>
                </Typography>
              )}
            </div>
            {description && (
              <>
                <Typography className={classes.description}>
                  <b>Brand description:</b>
                </Typography>
                <Typography
                  className={cn(classes.description, classes.marginBottom)}
                >
                  {description.split('\n').map((p, i) => (
                    <span key={`${p}-${i}`}>
                      {p}
                      <br />
                    </span>
                  ))}
                </Typography>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </>
    )
  },
)

export function ProductFooter({
  name,
  brandName,
  brandShortUrl,
  returnPolicy,
  myPrice,
  webShopPrice,
  onOpenQuantityDialog,
  quantity,
  onBuy,
  isLiked,
  onLike,
  likes,
  description,
  customDescription,
  warehouseCountries,
  ipLocation,
  deliveryTime,
  shipToIpLocation,
  exchangeRate,
  currency,
  shipToSelectedCountry,
  selectedCountry,
}: Props) {
  const classes = useStyles()
  const summaryEl = useRef(null)
  const [showDescription, setShowDescription] = useState(false)
  const toggleShowDescription = () => {
    setShowDescription(!showDescription)
  }
  const handleLike = () => {
    onLike()
  }

  const onDescriptionClick = () => {
    summaryEl.current.click()
    toggleShowDescription()
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {!shipToSelectedCountry ? (
          <div className={classes.shippingLabel}>
            <ReactCountryFlag
              style={{
                width: '15px',
                height: '15px',
                marginRight: '10px',
              }}
              countryCode={selectedCountry}
              svg
            />
            Shipping not available
          </div>
        ) : null}
        <div className={classes.infoSection}>
          <span className={classes.name}>{name}</span>
          <div>
            <span
              className={cn(classes.myPrice, classes.bold, 'my-price')}
              data-price={getPriceFormatted(myPrice, currency, exchangeRate)}
            >
              {getPriceFormatted(myPrice, currency, exchangeRate)}
              &nbsp;
            </span>
            <span className={classes.webShopPrice}>
              {getPriceFormatted(webShopPrice, currency, exchangeRate)}
              &nbsp;
            </span>
            <span
              className={classes.showDescription}
              onClick={onDescriptionClick}
            >
              {showDescription ? 'Hide' : 'View'} details
            </span>
          </div>
          <ProductDescription
            brandName={brandName}
            returnPolicy={returnPolicy}
            description={description}
            customDescription={customDescription}
            warehouseCountries={warehouseCountries}
            ipLocation={ipLocation}
            deliveryTime={deliveryTime}
            ref={summaryEl}
            shipToIpLocation={shipToIpLocation}
          />
        </div>
        <div className={classes.likeSection}>
          <IconButton
            onClick={handleLike}
            edge="end"
            className={classes.likeIcon}
            color="inherit"
            aria-label="menu"
          >
            {isLiked ? <LikeRedHeart /> : <LikeHeart />}
          </IconButton>
          <span className={classes.likeText}>{likes}</span>
        </div>
      </div>
      <div className={classes.actions}>
        <ProductQuantity
          quantity={quantity}
          onOpenQuantityDialog={onOpenQuantityDialog}
          className={classes.qtyButton}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.buyButton}
          onClick={onBuy}
          disableElevation
        >
          <ShoppingCartIcon
            className={cn(classes.leftIcon, classes.iconSmall)}
          />
          Buy from&nbsp;<strong>{brandShortUrl}</strong>
        </Button>
      </div>
    </div>
  )
}

export default ProductFooter
