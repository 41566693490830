import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DialogContainer from '../DialogContainer/DialogContainer'
import useGenerateDiscountCode from '../../hooks/useGenerateDiscountCode'
import CircularProgress from '@material-ui/core/CircularProgress'
const useStyles = (props: any) =>
  makeStyles((theme: any) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 500,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 400,
        maxWidth: 500,
      },
    },
    logo: {
      width: '150px',
      margin: '32px',
    },
    subTitle: {
      fontFamily: 'Nunito',
      fontSize: 12,
      lineHeight: '16px',
      color: '#999999',
      letterSpacing: '-0.02em',
      marginBottom: 10,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      padding: 25,
      paddingBottom: 30,
      paddingTop: 10,
    },
    input: {
      marginBottom: 15,
      marginTop: 5,
      minWidth: 250,
    },
    rootStyle: {
      height: 50,
      borderRadius: 10,
      background: '#9a9a9a08',
    },
    labelStyle: {
      top: -1,
      fontFamily: 'Nunito',
      fontSize: 16,
      letterSpacing: '-0.02em',
      color: '#999999',
    },
    item: {
      height: 30,
    },
    selectStyle: {
      '&::focus': {
        borderRadius: 10,
      },
    },
    button: {
      ...theme.styles.button,
      height: 50,
      marginTop: 5,
      ...theme.styles.blueButton,
    },
    heading1: {
      fontFamily: 'Nunito',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '29px',
      letterSpacing: '-0.02em',
      textAlign: 'center',
      marginBottom: '40px',
    },
    heading1NoCode: {
      fontFamily: 'Nunito',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '29px',
      letterSpacing: '-0.02em',
      textAlign: 'center',
    },

    loading: {
      fontFamily: 'Nunito',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '22px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#3F9AF1',
      display: 'flex',
    },
    progressIcon: {
      fontSize: 12,
      lineHeight: '13.8px',
      color: '#3F9AF1',
      marginBottom: -1,
      marginLeft: 1,
    },
    loadingText: {
      marginTop: '1px',
      marginLeft: '3px',
    },
    bottomText: {
      fontFamily: 'Nunito',
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: '-0.02em',
      textAlign: 'center',
      marginTop: '47px',
    },
    redirectButton: {
      height: '40px',
      width: '400px',
      left: '32.61%',
      right: '32.61%',
      top: '63.25%',
      bottom: '31.93%',
      background: '#3F9AF1',
      opacity: props.loading ? 0.3 : 1,
      borderRadius: '4px',
      color: 'white',
      marginTop: '61px',
      marginBottom: '30px',
      fontWeight: 700,
      lineHeight: '16.1px',
      fontSize: '15px',
      border: 'none',
      [theme.breakpoints.up('xs')]: {
        width: '250px',
      },
    },
    redirectButtonNoCode: {
      height: '40px',
      width: '400px',
      left: '32.61%',
      right: '32.61%',
      top: '63.25%',
      bottom: '31.93%',
      background: '#3F9AF1',
      opacity: props.loading ? 0.3 : 1,
      borderRadius: '4px',
      color: 'white',
      marginBottom: '30px',
      fontWeight: 700,
      lineHeight: '16.1px',
      fontSize: '15px',
      border: 'none',
      [theme.breakpoints.up('xs')]: {
        width: '250px',
      },
    },
    discountCode: {
      width: 'auto',
      height: '60px',
      border: '1px dashed #3F9AF1',
      boxSizing: 'border-box',
      fontFamily: 'Nunito',
      fontSize: '25px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '34px',
      letterSpacing: '0em',
      textAlign: 'center',
    },
    discountCodeText: {
      margin: '14px',
      color: '#3F9AF1',
    },
  }))

type Props = {
  discountPercentage?: number
  brandName?: string
  onClose: (...args: any[]) => void
  open: boolean
  webshopUrl: string
  userId: string
  settingsId: string
  shopReference?: string
  selectedCountry?: string
}

export function RedirectToStoreDialog({
  discountPercentage,
  brandName,
  onClose,
  open,
  userId,
  settingsId,
  selectedCountry,
  shopReference,
  webshopUrl,
}: Props) {
  const { data, loading } = useGenerateDiscountCode({
    settingsId,
    userId,
    selectedCountry,
  })
  const classes = useStyles({ loading })()
  const copyToClipBoardRef = useRef(null)
  useEffect(() => {
    if (copyToClipBoardRef.current) {
      copyToClipBoardRef.current.click()
    }
  }, [data])

  const handleStoreRedirection = () => {
    if (!loading) {
      window.open(
        data?.generateDiscountCode?.discountCode
          ? `https://${data?.generateDiscountCode?.redirectionUrl}/?ref=${shopReference}`
          : `https://${webshopUrl}/?ref=${shopReference}`,
      )
    }
  }

  return (
    <DialogContainer open={open} onClose={onClose}>
      <div className={classes.root}>
        <img
          alt="my-shop-logo"
          className={classes.logo}
          src="/static/images/logo-main.png"
        />
        <span className={classes.subTitle}></span>
        {loading ? (
          <div className={classes.heading1}>
            {`Generating your`}
            {` `}
            <strong>
              {discountPercentage}% {`discount code`}
            </strong>
          </div>
        ) : (
          <div
            className={
              !loading && !data.generateDiscountCode
                ? classes.heading1NoCode
                : classes.heading1
            }
          >
            {data?.generateDiscountCode ? (
              <>
                <strong>
                  {discountPercentage}% {`discount code`}
                </strong>
                {` `}
                {`copied to clipboard`}
              </>
            ) : (
              <>{`Unable to generate discount code for this shop`}</>
            )}
          </div>
        )}
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress
              className={classes.progressIcon}
              size={20}
              thickness={4.5}
            />
            <span className={classes.loadingText}>loading</span>
          </div>
        ) : (
          <div>
            {!loading && data?.generateDiscountCode ? (
              <CopyToClipboard text={data?.generateDiscountCode?.discountCode}>
                <button
                  ref={copyToClipBoardRef}
                  style={{ display: 'none' }}
                ></button>
              </CopyToClipboard>
            ) : null}
            {data?.generateDiscountCode ? (
              <div className={classes.discountCode}>
                <div className={classes.discountCodeText}>
                  {data?.generateDiscountCode?.discountCode}
                </div>
              </div>
            ) : null}
          </div>
        )}
        <div className={classes.bottomText}>
          {loading ? (
            <span>Please wait until code is generated</span>
          ) : data?.generateDiscountCode ? (
            <span>
              Ready and valid for 24 hours.
              <br />
              Use your discount code at checkout
            </span>
          ) : null}
        </div>
        <button
          className={
            !loading && !data.generateDiscountCode
              ? classes.redirectButtonNoCode
              : classes.redirectButton
          }
          onClick={handleStoreRedirection}
        >
          <div>{`Continue to ${brandName}`}</div>
        </button>
      </div>
    </DialogContainer>
  )
}

export default RedirectToStoreDialog
