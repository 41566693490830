import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Template from '../Template'

type Props = {
  match: {
    params: {
      shopUrl: string
    }
  }
}
const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    margin: '70px 40px',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    letterSpacing: '-0.02em',
  },
  h3: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    letterSpacing: '-0.02em',
    fontWeight: 'bold',
  },
  link: {
    color: '#999999',
  },
}))

function PrivatePolicy({
  match: {
    params: { shopUrl },
  },
}: Props) {
  const classes = useStyles()
  return (
    <Template shopUrl={shopUrl}>
      <div className={classes.container}>
        <h2 className={classes.title}>Privacy Policy</h2>
        <p className={classes.text}>
          Customer privacy is very important to us, and we’d like to outline in
          this document how any personal data collected from you via
          www.Myshop.io will be protected, stored and used by us to communicate
          with you.
        </p>
        <h3 className={classes.h3}>WHY DO WE COLLECT YOUR DATA?</h3>
        <p className={classes.text}>
          We collect, use and store your basic personal details to process and
          send your orders to the correct person and location. We also collect
          it to keep you in the know about the latest products, special offers
          and sales from our Merchant partners. The more personal information we
          have about you, the more tailored we can make your email newsletters,
          best suited to your interests and style.
        </p>
        <h3 className={classes.h3}>IS IT SECURE?</h3>
        <p className={classes.text}>
          Yes, it is very safe to leave your personal details with us. We use
          market-leading technology and software to ensure your personal data is
          secure. If we work with any third party services, we always ensure
          they have the correct, industry standard security measures to access
          our database.
        </p>
        <h3 className={classes.h3}>HOW DO WE USE YOUR DATA?</h3>
        <p className={classes.text}>
          You can rest assured that once you provide us with your personal
          details, they will be kept secure. We do not sell any of your personal
          data to any third party – including your name, phone number, address,
          email address and credit card/payment information.
        </p>
        <p className={classes.text}>
          However, we share your data with the following categories of companies
          as an essential part of being able to provide our services to you, as
          set out in this statement:
        </p>
        <ul className={classes.text}>
          <li>
            Companies that we use to get your orders to you, such as payment
            service providers, warehouses, and delivery companies
          </li>
          <li>
            Professional service providers who help us to run our business, such
            as advertising partners, marketing technology platforms and website
            hosts
          </li>
          <li>
            Merchants from whom you place the order will also store your
            personal details according to their own privacy policies. These
            respective privacy policies can be reviewed during checkout and on
            the merchants websites.
          </li>
        </ul>
        <p className={classes.text}>
          As the 1st party data processor, we will process your personal data in
          the following ways:
        </p>
        <p className={classes.text}>If you browse our website:</p>
        <ul className={classes.text}>
          <li>IP address</li>
          <li>On-site behavior for marketing purposes</li>
        </ul>
        <p className={classes.text}>If you sign up to our newsletter:</p>
        <ul className={classes.text}>
          <li>Email address</li>
          <li>Your newsletter preferences</li>
          <li>
            What products you have clicked on and/or put in your shopping cart
          </li>
        </ul>
        <p className={classes.text}>
          If you place or have previously placed anorder through us:
        </p>
        <ul className={classes.text}>
          <li>Email address</li>
          <li>Full name</li>
          <li>Phone number</li>
          <li>Delivery and/or billing address</li>
          <li>Gender</li>
          <li>
            Credit card information (we securely pass on your data to our
            payment partner Stripe, we do not store your credit card info)
          </li>
        </ul>
        <p className={classes.text}>
          If you order products on behalf of a third party, you are held
          responsible for gaining their consent to submitting their personal
          data.
        </p>
        <h3 className={classes.h3}>HOW LONG DO WE SAVE YOUR DATA?</h3>
        <p className={classes.text}>
          We will keep your data for direct marketing for a total of five years.
          For email marketing, we will consider you an inactive customer (and
          subsequently delete you data) if you haven&apos;t opened an email
          within the last three years. After these time periods your personal
          data will be deleted.
        </p>
        <p className={classes.text}>
          We reserve the right to save your data for legal and tax purposes for
          a total of five years. You may opt out of our direct marketing during
          this period.
        </p>
        <h3 className={classes.h3}>WHAT ABOUT COOKIES?</h3>
        <p className={classes.text}>
          A cookie is a small text file that is saved to, and, during subsequent
          visits, retrieved from your computer or mobile device. If you use our
          online services, we will assume that you agree to the use of such
          cookie.
        </p>
        <p className={classes.text}>
          We use the following cookies when you visit our site:
        </p>
        <ol className={classes.text}>
          <li>
            Site functionality cookies – allow you to navigate the site and use
            our features, such as “Add to Bag” and “Add to Wishlist”.
          </li>
          <li>
            Site analytics cookies – allow us to measure and analyze how our
            customers use our website, to improve both its functionality and the
            customer experience.
          </li>
          <li>
            Customer preference cookies – when you’re browsing or shopping on
            our site, these cookies will remember your preferences (e.g. your
            location), so we can make your shopping experience as seamless as
            possible, and quicker for you to order your purchases.
          </li>
          <li>
            Targeting or advertising cookies – used to deliver adverts relevant
            to you. They also limit the number of times that you see an ad, and
            help us measure the effectiveness of our marketing campaigns.
          </li>
        </ol>
        <p className={classes.text}>
          We also use 3rd party services that may also have added cookies to
          your browser.
        </p>
        <p className={classes.text}>
          By using our site, you agree to MyShop.io placing these sorts of
          cookies on your device and accessing them when you visit our site in
          the future. If you want to delete any cookies that are already on your
          computer, visit the “Help” section in your browser to delete any you
          wish to. Please note, deleting or disabling future cookies may affect
          your user experience; you might not be able to take advantage of
          certain functions on our site, and your shopping experience may be
          less seamless.
        </p>
        <h3 className={classes.h3}>HOW DO I CHANGE MY MAIL SETTINGS?</h3>
        <p className={classes.text}>
          We have a one click unsubscribe policy, and each communication gives
          you the opportunity to opt-out of receiving future MyShop.io
          newsletters via a link at the bottom of the email.
        </p>
        <p className={classes.text}>
          Please note there are some communications that are an integral part of
          our company service and cannot be opted-out of. These communications
          include changes to your order, including information about a potential
          refund or exchange.
        </p>
        <h3 className={classes.h3}>CHANGES TO THIS PRIVACY STATEMENT</h3>
        <p className={classes.text}>
          This Privacy Policy is correct as of 04/08/2020
        </p>
        <h3 className={classes.h3}>REGISTERED DETAILS</h3>
        <p className={classes.text}>
          MyShop.io is a part of Brandbassador AS. Our registered office and
          principal trading address is: Brandbassador AS, Eilert Sundts Gate 41,
          0355 Oslo, Norway. Our company registration number in Norway is
          916075847.
        </p>
        <p className={classes.text}>
          Local Brandbassador entities are as follows:
        </p>
        <ul className={classes.text}>
          <li>UK & Australia: Brandbassador Ltd.</li>
        </ul>
        <p className={classes.text}>
          The MyShop.io entity responsible for your purchases on our website and
          the processing of your personal data will depend on how you interact
          with our site and where you are located in the world.
        </p>
        <h3 className={classes.h3}>CONTACT US</h3>
        <p className={classes.text}>
          If you have any queries about our customer privacy policy, or anything
          else, please contact us at
          <a className={classes.link} href="mailto:support@myshop.io">
            support@myshop.io
          </a>
          .
        </p>
      </div>
    </Template>
  )
}

export default PrivatePolicy
