import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { HashLink } from 'react-router-hash-link'
import { Product } from '../../types'

type Props = {
  product: Product
  onClick?: () => void
  scroll?: (el) => void
  shipToSelectedCountry?: boolean
}

const useStyles = (props: { shipToSelectedCountry: boolean }) =>
  makeStyles({
    picture: {
      maxWidth: '100%',
      maxHeight: 300,
      verticalAlign: 'top',
      objectFit: 'cover',
      objectPosition: '100% 0',
      opacity: props.shipToSelectedCountry ? 1 : 0.5,
    },
  })

function CellProduct({
  product,
  onClick,
  scroll,
  shipToSelectedCountry,
}: Props) {
  const classes = useStyles({ shipToSelectedCountry })()

  return (
    <HashLink to={`#${product.id}`} onClick={onClick} scroll={scroll}>
      <img
        alt={product.name || 'product'}
        src={product?.photos[0]}
        className={classes.picture}
      />
    </HashLink>
  )
}

function productPropsAreEqual(prevProps, nextProps) {
  return (
    prevProps?.product.photos[0] === nextProps?.product.photos[0] &&
    prevProps?.shipToSelectedCountry === nextProps?.shipToSelectedCountry
  )
}

export default React.memo(CellProduct, productPropsAreEqual)
