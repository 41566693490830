import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog/Dialog'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    color: theme.palette.standard,
    background: theme.palette.white,
    height: 48,
    minHeight: 'unset',
    [theme.breakpoints.up('sm')]: {
      minHeight: 60,
    },
  },
  logo: {
    width: 135,
    paddingLeft: 18,
    [theme.breakpoints.up('sm')]: {
      width: 180,
    },
  },
  menuButton: {},
  menuIcon: {
    fontSize: '2rem',
    color: '#303466',
  },
  appBar: {
    boxShadow: theme.styles.shadow,
  },
  menu: {
    maxWidth: 500,
    width: '90%',
    minHeight: 300,
    background: theme.palette.white,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.06)',
    borderRadius: 30,
    zIndex: 100,
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
    marginTop: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 25,
  },
  description: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.02em',
    color: '#393939',
  },
}))

function ReturnPolicy(props: {
  brandName: string
  content: string
  open: boolean
  onClose: (event: any) => void
}) {
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paper: classes.menu }}
      open={props.open}
      keepMounted
      onClose={props.onClose}
    >
      <div className={classes.content}>
        <h3 className={classes.title}>{props.brandName} Return Policy</h3>
        <Typography className={classes.description}>{props.content}</Typography>
      </div>
    </Dialog>
  )
}

export default ReturnPolicy
