import { gql } from 'apollo-boost'

const CreatePayment = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      paymentIntent {
        id
        status
        amount
        client_secret
        next_action {
          type
        }
      }
      orderId
      errors {
        message
        code
      }
    }
  }
`

export default CreatePayment
