import React, { useCallback, useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'

import OwnerDetails from '../../components/OwnerDetails/OwnerDetails'
import QuantityDialog from '../../components/QuantityDialog/QuantityDialog'
import { useGetShopProductsByUrl } from '../../hooks/useGetShopByUrl'
import { useHandleOpenDialog } from '../../hooks/useHandlers'
import { useStepper } from '../../hooks/useStepper'
import { Steps } from '../../Constants'
import Loader from '../../components/Loader/Loader'
import ProductsGrid from '../../components/ProductsGrid/ProductsGrid'
import Template from '../Template'
import { Country, Currency, Product } from '../../types'
import { useLikeProducts } from '../../hooks/useLikeProducts'
import { AppState, initState, reducer } from './Store'
import {
  getCountries,
  getProduct,
  getShippingFlatRate,
  getVariants,
} from './helpers'
import CheckoutDialog from '../../components/CheckoutDialog'
import { useOrdersHistory } from '../../hooks/useOrdersHistory'
import FirstTimeDialog from '../../components/FirstTimeDialog'
import { useGetIPInfo } from '../../hooks/useGetIPInfo'
import GoogleAnalytics from '../../lib/GoogleAnalytics'
import Badges from '../../components/Badges'

type Props = {
  location: {
    search: string
  }
  match: {
    params: {
      shopUrl: string
    }
  }
}

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: theme.palette.background.default,
      maxWidth: 800,
      margin: 'auto',
    },
    header: {},
    ownerDetails: {
      marginTop: 60,
      [theme.breakpoints.down('xs')]: {
        marginTop: 50,
      },
    },
    products: {
      columnCount: 2,
      [theme.breakpoints.down('xs')]: {
        columnCount: 1,
      },
      columnGap: '1em',
      width: '100%',
    },
    tabs: {
      width: '100%',
      background: theme.palette.white,
    },
  }
})

export function Shop({
  location: { search },
  match: {
    params: { shopUrl },
  },
}: Props) {
  const classes = useStyles()
  // state
  const [state, dispatch] = useReducer(reducer, initState as AppState)
  const [currentProductId, setCurrentProductId] = React.useState(null)
  const [step, goToNextStep, goToPreviousStep, resetStep, jumpTo] = useStepper()

  const [preview, setPreview] = useState<Product>()
  const [previewIndex, setPreviewIndex] = useState<number>(-1)
  const [checkedBrands, setCheckedBrands] = useState({})

  const [likeProduct, isProductLiked] = useLikeProducts(
    shopUrl,
    preview,
    setPreview,
  )

  // TODO: use the first destructed element which is the list of cashed orders
  const [, onAddToOrdersHistory] = useOrdersHistory()

  // dialogs control
  const [
    openQuantityDialog,
    handleOpenQuantityDialog,
    handleCloseQuantityDialog,
  ] = useHandleOpenDialog(setCurrentProductId)

  // IP information
  const [ipInfo] = useGetIPInfo()

  const getQuantity = (productId: string) => {
    if (state.quantities[productId] !== 0 && !state.quantities[productId])
      return 1

    return state.quantities[productId]
  }

  const handleClose = () => {
    resetStep()
    setCurrentProductId(null)
    GoogleAnalytics.track({
      category: 'Product',
      action: 'Close Buy Dialog',
    })
  }
  const handleBuy = (productId, products: Product[]) => () => {
    resetStep()
    setCurrentProductId(productId)
    GoogleAnalytics.track({
      category: 'Product',
      action: 'Open Buy Dialog',
    })
    goToNextStep(productId, products)
  }

  const handleContinueShippingDialog = (productId, products) => values => {
    dispatch({
      type: 'SET_SHIPPING_COUNTRY',
      payload: { country: values.country, province: values.province },
    })

    /** Set the variant to the first one */
    const variants = getVariants(productId, products, values.country)
    const variant = variants?.length ? variants[0] : null

    dispatch({ type: 'SET_VARIANT', payload: { variant } })

    if (variants?.length > 1) {
      // Going From Select Country To Make Your Selection (Variants)
      GoogleAnalytics.track({
        category: 'Product',
        action: 'Continue To Make Your Selection (Variants)',
      })
    } else {
      // Going From Select Country To Payment Method
      GoogleAnalytics.track({
        category: 'Product',
        action: 'Continue To Payment Method',
      })
    }

    goToNextStep(productId, products, false, values.country)
  }

  const handleContinueProductFeaturesDialog = (
    productId,
    products: Product[],
  ) => values => {
    const product = products.find(product => product.id === productId)

    GoogleAnalytics.track({
      category: 'Product',
      action: 'Continue To Payment Method',
    })

    const variant = product.variants.find(
      variant => variant.variantId === values.variant,
    )
    dispatch({ type: 'SET_VARIANT', payload: { variant } })
    goToNextStep(productId, products)
  }

  const handleContinuePaymentMethode = (
    productId,
    products: Product[],
  ) => () => {
    GoogleAnalytics.track({
      category: 'Product',
      action: 'Select Card Payment, And Continue To Shipping Address',
    })

    goToNextStep(productId, products)
  }

  const handleContinueShippingAddressDialog = (
    productId,
    products: Product[],
  ) => async shippingDetails => {
    dispatch({
      type: 'SET_SHIPPING_DETAILS',
      payload: { details: shippingDetails },
    })

    // set the billing details to shipping details if isBillingAddressTheSame checkbox is checked
    if (shippingDetails.isBillingAddressTheSame) {
      dispatch({
        type: 'SET_BILLING_DETAILS',
        payload: { details: shippingDetails },
      })
      GoogleAnalytics.track({
        category: 'Product',
        action: 'Continue To Payment (Card)',
      })
    } else {
      GoogleAnalytics.track({
        category: 'Product',
        action: 'Continue To Billing Details',
      })
    }

    goToNextStep(productId, products, shippingDetails.isBillingAddressTheSame)
  }

  const handleContinueBillingDetailsDialog = (
    productId,
    products: Product[],
  ) => billingDetails => {
    dispatch({
      type: 'SET_BILLING_DETAILS',
      payload: { details: billingDetails },
    })
    GoogleAnalytics.track({
      category: 'Product',
      action: 'Continue To Payment (Card)',
    })
    goToNextStep(productId, products)
  }

  const handleSuccess = (productId, products: Product[]) => (
    skiptoSecussStep = false,
  ) => {
    goToNextStep(productId, products, undefined, undefined, skiptoSecussStep)
  }

  const handlePrevious = (productId, products: Product[]) => () => {
    goToPreviousStep(productId, products, state?.shippingDetails?.country)
  }

  const setOrderId = orderId => {
    dispatch({ type: 'SET_ORDER_ID', payload: { orderId } })
  }

  const handleTermsAndConditions = (shippingDetails): void => {
    // Save shipping address
    dispatch({
      type: 'SET_SHIPPING_DETAILS',
      payload: { details: shippingDetails },
    })

    // Going From Shipping Address To Terms and Conditions
    GoogleAnalytics.track({
      category: 'Product',
      action: 'Go From Shipping Address To Terms and conditions',
    })

    jumpTo(Steps.TERMS_AND_POLICIES)
  }

  const handleTermsAndConditionsPrevious = (): void => {
    // Going From Terms and ConditionsTo Shipping Address
    GoogleAnalytics.track({
      category: 'Product',
      action: 'Return From Terms and conditions To Shipping Address',
    })
    jumpTo(Steps.SHIPPING_ADDRESS)
  }

  const handlePaymentDialogPrevious = (isBillingAddressTheSame): void => {
    if (isBillingAddressTheSame) {
      jumpTo(Steps.SHIPPING_ADDRESS)
    } else {
      jumpTo(Steps.BILLING_ADDRESS)
    }
  }

  const handleBuyerAcceptsMarketing = () => (buyerAcceptsMarketing): void => {
    dispatch({
      type: 'SET_BUYER_ACCEPTS_MARKETING',
      payload: { buyerAcceptsMarketing },
    })
  }
  const {
    shippingDetails,
    billingDetails,
    variant,
    orderId,
    buyerAcceptsMarketing,
    country,
    currency,
    rate: exchangeRate,
    shipToSelectedCountry,
  } = state

  const { loading, error, shop, loadMore } = useGetShopProductsByUrl(
    shopUrl,
    !shipToSelectedCountry
      ? country && country.code
        ? country.code
        : ipInfo.country
      : null,
    true,
  )

  const {
    logo,
    productsCount,
    shopName,
    shopBio,
    salesCount,
    likes,
    productConnection,
    achievements,
    url,
    id,
  } = shop || {}

  const products: Product[] =
    productConnection && productConnection.edges.map(edge => edge.node)
  const hasMore = productConnection && productConnection.pageInfo.hasNextPage

  const onPreview = useCallback(
    (product: Product, index: number): void => {
      // save the clicked product under @preview
      setPreview(product)
      if (!product) {
        return
      }
      // and set the previewIndex to the current clicked product if it is the third
      // Item of a row so it will be under the row
      // Set the previewIndex to be currentIndex + 1 if it is the item in the midlle
      // So the preview will be the item after the row not in the middle of it.
      // Same if clicked item is the first item of the row we add 2 so it will be
      // After the current row.
      let add = index % 3 === 0 ? 2 : index % 3 === 1 ? 1 : 0

      // if the last item is clicked, or the item before and there is no more
      // products to add the preview before them we add the preview directly
      // or after one item if there is any.
      if ((add === 1 || add === 2) && products.length - 1 < index + 1) {
        add = 0
      } else if (add === 2 && products.length - 1 < index + 2) {
        add = 1
      }
      setPreviewIndex(index + add)
    },
    [products],
  )
  const onCheckedBrands = useCallback(
    (brandName: string): void => {
      // Save the brands that the checking flow from the FeedView
      // Save the brands that the checking flow from the FeedView
      setCheckedBrands({ ...checkedBrands, [brandName]: true })
    },
    [checkedBrands],
  )

  const setCountry = (country: Country) => {
    dispatch({
      type: 'SET_COUNTRY',
      payload: { country },
    })
  }

  const setCurrency = (currency: Currency) => {
    dispatch({
      type: 'SET_CURRENCY',
      payload: { currency },
    })
  }

  const setExchangeRate = (exchangeRate: number) => {
    dispatch({
      type: 'SET_CURRENCY_EXCHANGE_RATE',
      payload: { rate: exchangeRate },
    })
  }

  const setShipToSelectedCountry = (shipToSelectedCountry: boolean) => {
    dispatch({
      type: 'SET_SHIP_TO_SELECTED_COUNTRY',
      payload: { shipToSelectedCountry },
    })
  }

  const setDataInStore = ({
    country,
    currency,
    exchangeRate,
    shipToSelectedCountry,
  }: {
    country?: Country
    currency?: Currency
    exchangeRate?: number
    shipToSelectedCountry?: boolean
  }) => {
    if (country && setCountry) setCountry(country)
    if (currency && setCurrency) setCurrency(currency)
    if (exchangeRate && setExchangeRate) setExchangeRate(exchangeRate)
    if (shipToSelectedCountry !== undefined && setExchangeRate)
      setShipToSelectedCountry(shipToSelectedCountry)
  }

  if (loading) return <Loader />
  if (error || !shop) return <Redirect to={{ pathname: '/Error/404' }} />

  return (
    <Template
      shopUrl={shopUrl}
      setDataInStore={setDataInStore}
      country={country}
      currency={currency}
      shipToSelectedCountry={shipToSelectedCountry}
    >
      <OwnerDetails
        logo={logo || undefined}
        products={productsCount || 0}
        sales={salesCount || 0}
        likes={likes || 0}
        shopName={shopName || ''}
        shopBio={shopBio || ''}
        className={classes.ownerDetails}
        achievements={achievements}
        selectedCountry={country?.code}
      />

      <Badges
        achievements={achievements}
        logo={logo || undefined}
        shopName={shopName || ''}
      />

      <ProductsGrid
        userId={id}
        products={products}
        shopReference={url}
        getQuantity={getQuantity}
        onOpenQuantityDialog={handleOpenQuantityDialog}
        onBuy={handleBuy}
        loadMore={() => loadMore(products.length)}
        hasMore={hasMore}
        onLike={likeProduct}
        isProductLiked={isProductLiked}
        ipInfo={ipInfo}
        preview={preview}
        previewIndex={previewIndex}
        onPreview={onPreview}
        onCheckedBrands={onCheckedBrands}
        checkedBrands={checkedBrands}
        exchangeRate={exchangeRate}
        currency={currency}
        selectedCountry={country?.code}
        getShippingFlatRate={(productId: string): number =>
          getShippingFlatRate
            ? getShippingFlatRate(
                productId,
                products,
                country ? country.code : null,
              )
            : 0
        }
      />
      <QuantityDialog
        open={openQuantityDialog}
        onClose={handleCloseQuantityDialog}
        quantity={getQuantity(currentProductId)}
        productId={currentProductId}
        dispatch={dispatch}
      />
      <CheckoutDialog
        open={step !== Steps.NO_DIALOG}
        onClose={handleClose}
        step={step}
        countries={getCountries(currentProductId, products)}
        variants={getVariants(
          currentProductId,
          products,
          state?.shippingDetails?.country,
        )}
        shippingFlatRate={
          getShippingFlatRate
            ? getShippingFlatRate(
                currentProductId,
                products,
                state?.shippingDetails?.country || ipInfo.country,
              )
            : 0
        }
        onPrevious={handlePrevious(currentProductId, products)}
        shippingDetails={shippingDetails}
        billingDetails={billingDetails}
        orderDetails={{
          variant,
          shippingDetails,
          billingDetails,
          buyerAcceptsMarketing,
        }}
        product={getProduct(currentProductId, products)}
        quantity={getQuantity(currentProductId)}
        onContinueShippingDialog={handleContinueShippingDialog(
          currentProductId,
          products,
        )}
        handleContinuePaymentMethode={handleContinuePaymentMethode(
          currentProductId,
          products,
        )}
        onContinueProductFeaturesDialog={handleContinueProductFeaturesDialog(
          currentProductId,
          products,
        )}
        onContinueShippingAddressDialog={handleContinueShippingAddressDialog(
          currentProductId,
          products,
        )}
        onContinueBillingDetailsDialog={handleContinueBillingDetailsDialog(
          currentProductId,
          products,
        )}
        onSuccess={handleSuccess(currentProductId, products)}
        setOrderId={setOrderId}
        orderId={orderId}
        supportEmail={variant.supportEmail}
        onTermsAndConditions={handleTermsAndConditions}
        onTermsAndConditionsPrevious={() => handleTermsAndConditionsPrevious()}
        onPaymentDialogPrevious={isBillingAddressTheSame =>
          handlePaymentDialogPrevious(isBillingAddressTheSame)
        }
        shopUrl={shopUrl}
        onBuyerAcceptsMarketing={handleBuyerAcceptsMarketing()}
        onAddToOrdersHistory={onAddToOrdersHistory}
        ipInfo={ipInfo}
        exchangeRate={exchangeRate}
        currency={currency}
      />
      <FirstTimeDialog
        search={search}
        shopName={shopName || ''}
        shopUrl={shopUrl}
      />
    </Template>
  )
}

export default Shop
