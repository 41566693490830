export const Steps = {
  NO_DIALOG: 0,
  SHIPPING_COUNTRIES: 1,
  PRODUCT_VARIANTS: 2,
  PAYMENT_METHODE: 2.1,
  SHIPPING_ADDRESS: 3,
  TERMS_AND_POLICIES: 3.1,
  BILLING_ADDRESS: 4,
  PAYMENT: 5,
  SUCCESS: 6,
}

export const stripeStatus = {
  REQUIRES_CONFIRMATION: 'requires_confirmation',
  REQUIRES_CAPTURE: 'requires_capture',
  REQUIRES_ACTION: 'requires_action',
  CANCELED: 'canceled',
  SUCCEEDED: 'succeeded',
}

export const PaymentAccountTypes = {
  STRIPE: 'stripe',
  INVOICING: 'invoicing',
}

export const PaymentStatus = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}
