import { currencies } from './data'
import { Currency } from '../../types'

export const getAllCurrencies = (): Array<Currency> => currencies

export const getCurrencyByCode = (code: string): Currency => {
  return currencies.find((currency: Currency) => currency.code === code)
}

export const getCurrenciesByCountryCode = (
  countryCode: string,
): Array<Currency> => {
  return currencies.filter(
    (currency: Currency) => currency.countries.indexOf(countryCode) > -1,
  )
}
