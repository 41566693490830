import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

const propTypes = {
  shopName: PropTypes.string,
  shopBio: PropTypes.string,
}

const defaultProps = {
  shopName: '',
  shopBio: '',
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    marginTop: 0,
    // marginBottom: 15,
  },
  shopName: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.standard,
    margin: 0,
  },
  shopBio: {
    fontSize: 13,
    fontWeight: 400,
    color: theme.palette.standard,
    margin: 0,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  text: {
    fontSize: 13,
    fontFamily: 'Helvetica',
    lineHeight: '18px',
    color: theme.palette.standard,
    margin: 0,
  },
}))

export function ShopDetails({ shopName, shopBio }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <p className={classes.shopName}>{shopName}</p>
        </Grid>
        <Grid item>
          <Typography className={classes.shopBio}>
            {shopBio ||
              'Welcome to myshop! Here I showcase some of my favourite products from some awesome brands.'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

ShopDetails.propTypes = propTypes
ShopDetails.defaultProps = defaultProps

export default ShopDetails
