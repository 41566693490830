import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Checkbox } from '@material-ui/core'
import Title from '../Title/Title'
type Props = {
  title: string
  onPrevious?: (args: any) => void
  brandName?: string
  privacyPolicyUrl?: string
  termsAndConditionsUrl?: string
  shopUrl: string
  buyerAcceptsMarketing: boolean
  onBuyerAcceptsMarketing: (args: any) => void
  onClose: () => void
}

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 25,
    position: 'relative',
    width: '100%',
    padding: '0 15%',
    minHeight: 'calc(100vh - 155px)',
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: '30px 15px',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      margin: 25,
    },
  },
  description: {
    fontFamily: 'Nunito',
    fontSize: 16,
    lineHeight: '22px',
    color: '#999999',
    paddingBottom: 19,
    margin: 0,
    marginTop: 10,
    fontWeight: 'bold',
  },
  arrowBack: {
    position: 'absolute',
    top: 35,
    left: 25,
    [theme.breakpoints.up('sm')]: {
      top: 30,
    },
  },
  labelStyle: {
    top: -1,
    fontFamily: 'Nunito',
    fontSize: 16,
    letterSpacing: '-0.02em',
    color: '#999999',
  },
  textField: {
    ...theme.styles.textField,
    width: '100%',
    marginBottom: 15,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 15,
    },
  },
  twoFields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  secondField: {
    marginLeft: 10,
  },
  input: {
    ...theme.styles.input,
  },
  checkbox: {
    marginBottom: 15,
    lineHeight: '21px',
    color: '#999999',
    fontSize: 16,
  },
  item: {
    height: 30,
  },
  selectStyle: {
    '&::focus': {
      borderRadius: 10,
    },
  },
  button: {
    ...theme.styles.button,
    height: 50,
    width: '100%',
    ...theme.styles.blueButton,
  },
  termsAndPolicies: {
    textDecoration: 'underline',
  },
  menu: {
    maxWidth: 500,
    width: '90%',
    minHeight: 300,
    background: theme.palette.white,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.06)',
    borderRadius: 30,
    zIndex: 100,
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  link: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '22px',
    color: '#999999',
    paddingBottom: 10,
    textDecoration: 'underline',
  },
  checkboxRootStyle: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: -11,
    marginRight: 16,
    marginBottom: 15,
    verticalAlign: 'middle',
  },
  checkboxLabelStyle: {
    top: -1,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    letterSpacing: '0.00938em',
    color: '#999999',
    fontSize: 14,
    lineHeight: '21px',
  },
}))

export function TermsAndPoliciesDialog({
  title,
  onPrevious,
  brandName,
  privacyPolicyUrl,
  termsAndConditionsUrl,
  shopUrl,
  buyerAcceptsMarketing,
  onBuyerAcceptsMarketing,
  onClose,
}: Props) {
  const classes = useStyles()
  const urlPrefix = shopUrl ? `/${shopUrl}` : ''
  return (
    <div className={classes.wrapper}>
      <Title title={title} onClose={onClose} onPrevious={onPrevious} />
      <div className={classes.root}>
        <p className={classes.description}>
          I agree to the following Terms
          <br />& Conditions and Privacy Policies
        </p>
        <div className={classes.menuItems}>
          {termsAndConditionsUrl && (
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              href={termsAndConditionsUrl}
            >
              {brandName} Terms & conditions
            </a>
          )}
          {privacyPolicyUrl && (
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              href={privacyPolicyUrl}
            >
              {brandName} Privacy Policy
            </a>
          )}
          <Link
            to={`${urlPrefix}/terms_and_conditions`}
            target="_blank"
            className={classes.link}
          >
            MyShop Terms & conditions
          </Link>
          <Link
            to={`${urlPrefix}/privacy_policy`}
            target="_blank"
            className={classes.link}
          >
            MyShop Privacy Policy
          </Link>
        </div>
        <label className={classes.checkboxRootStyle}>
          <Checkbox
            checked={buyerAcceptsMarketing}
            name="isGetEmails"
            onChange={(): void =>
              onBuyerAcceptsMarketing(!buyerAcceptsMarketing)
            }
            color="primary"
          />
          <span className={classes.checkboxLabelStyle}>
            Get exclusive e-mail offers & invites
          </span>
        </label>
      </div>
    </div>
  )
}

export default TermsAndPoliciesDialog
