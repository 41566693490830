import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    paddingBottom: 20,
    color: '#999999',
  },
  links: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    paddingBottom: 20,
    color: '#999999',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const useClickOutside = (ref, callback) => {
  const handleClick = e => {
    if (ref.current) {
      callback(e)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const CreatLink = (props: any) => {
  const styles = useStyles()
  const clickRef = useRef()
  const history = useHistory()

  useClickOutside(clickRef, e => {
    history.push(e.target.id.split('-')[1])
  })
  return (
    <span className={styles.links} ref={clickRef}>
      {props.children}
    </span>
  )
}

export const BuySomething = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      To buy something on MyShop click the Buy button under any given product.
      You may need to select specifications for your purchase (example: color,
      size).
      <br />
      <br />
      1. Tap Buy on Instagram or Add to Bag.
      <br />
      2. You may need to select specifications for your purchase (example:
      color, size).
      <br />
      3. Enter your Delivery Address then tap Next.
      <br />
      Enter your Payment Method.
      <br />
      <br />
      Note: If you’re your billing address is different from your delivery
      address you need to untick the checkbox “Billing address is the same”
      <br />
      <br />
      Once you place your order you will receive an order confirmation e-mail
      from the brand you purchased the product from.
      <br />
    </p>
  )
}

export const ContactSeller = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      If you have an issue with something you purchased through MyShop, you can
      contact the respective webshop/brand for help. MyShop.io does not offer
      any support related to orders placed through the platform.
      <br />
      <br />
      When you placed the order you received an order confirmation from the
      respective webshop/brand. Check this email for customer support details.
      <br />
      <br />
      If you cannot find any customer support details in the order confirmation
      email from the webshop/brand, then go to their website and find their
      support page.
      <br />
      <br />
      You can also check the <strong>My Orders</strong> page here on MyShop for
      a support email related to your order. (Please note that this page is only
      cookie based and if you delete your history this order history will be
      deleted.) )
    </p>
  )
}

export const TrackerOrders = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      When you placed the order you received an order confirmation from the
      respective webshop/brand. Check this email for tracking information
      details.
      <br />
      <br />
      Most brands will keep you posted on the status of your order through
      email.
      <br />
      <br />
      If you have any questions{' '}
      <strong>
        <CreatLink>
          <span id={'1-/faq/2'}>
            please contact the respective webshop/brand
          </span>
        </CreatLink>
      </strong>
    </p>
  )
}

export const OrderConfirmation = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      When you buy something through MyShop, the respective brands will send you
      an order confirmation to the email you entered at checkout.
      <br />
      <br />
      If you did not receive an order confirmation but you have been charged for
      the purchase, see this question:{' '}
      <strong>
        <CreatLink>
          <span id={'2-/faq/6'}>
            I did not get an order confirmation e-mail, what do I do?
          </span>
        </CreatLink>
      </strong>
    </p>
  )
}

export const CancelOrder = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      To cancel your order you need to contact the respective webshop/brand from
      where the product was purchased.
      <br />
      <br />
      Go here to see how to{' '}
      <strong>
        <CreatLink>
          <span id={'3-/faq/2'}>contact the webshop/brand </span>
        </CreatLink>
      </strong>
    </p>
  )
}

export const OrderConfirmationEmail = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      If you did not get an order confirmation email, but you have been charged
      by the brand you should contact the brand directly by visiting their
      website.
      <br />
      <br /> If you do not know the name or URL of the webshop/brand from where
      the order was placed you can look under <strong>My Orders</strong>.
      (Please note that the My Orders page is only cookie based and if you
      delete your browsing history this order history will also be deleted.)
    </p>
  )
}

export const HowItWorks = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      We wanted to create a decentralized social commerce platform where every
      party receives a piece of the pie, not just the big players. We call it
      4XW (Win, Win, Win, Win), and this is how everyone wins:
      <br />
      <br />
      <strong>The customer:</strong> With MyShop you as the customer always
      receive a discount and it’s always free shipping. The size of the discount
      is controlled by the respective brands and can vary.
      <br />
      <br />
      <strong>The shop owner:</strong> The person who owns the MyShop account
      receives a commission on the sales from their specific MyShop.
      <br />
      <br />
      <strong>The Brand:</strong> The brand receives an order and added revenue.
      <br />
      <br />
      <strong>The Platform:</strong> We as a platform receive a commission on
      the sales that are created through the platform. How it works:
      <br />
      <br />
      When you place an order for a product on MyShop, the order is placed
      directly in the webshop of the actual brand and you will receive an order
      confirmation e-mail from the brand itself.
      <br />
      <br />
      When it comes to payments, MyShop handles the payments and makes sure that
      the brand and the shop owner gets their money and commission. For you as a
      customer it is as smooth and secure as it should be to shop online.
      <br />
      <br />
      All brands on MyShop go through a manual screening process to be able to
      sell their products through the platform. They also need to meet our
      strict guidelines and commerce policy.
    </p>
  )
}

export const SafeToBuy = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      All brands on MyShop go through a manual screening process before they are
      able to sell their products through the platform.They also need to meet
      our strict guidelines and commerce policy to offer their products and
      services on the platform.
      <br />
      <br />
      How can I set up my own MyShop?
      <br />
      <br />
      To set up your own MyShop is super simple. Just download the Brandbassador
      app and you can set up your own MyShop in less than 60 seconds.
      <br />
      <br />
      Download Brandbassador for
      <br />
      <strong>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={
            'https://play.google.com/store/apps/details?id=com.brandbassadoras.brandbassador'
          }
        >
          Android
        </a>
      </strong>
      <br />
      <strong>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={'https://apps.apple.com/us/app/brandbassador/id1304541251'}
        >
          iOS
        </a>
      </strong>
    </p>
  )
}

export const ConnectMyFeed = () => {
  const styles = useStyles()
  return (
    <p className={styles.text}>
      If you run a brand or webshop and want to connect your e-com product feed
      to the MyShop product catalog. Please visit MyShop.io for more details
    </p>
  )
}
