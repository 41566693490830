import { gql } from 'apollo-boost'

const GenerateDiscountCode = gql`
  query generateDiscountCode($input: GenerateDiscountInput!) {
    generateDiscountCode(input: $input) {
      discountCode
      redirectionUrl
    }
  }
`

export default GenerateDiscountCode
