import useLocalStorage from 'react-use/lib/useLocalStorage'

const ORDERS_KEY = 'my-shop-orders-history'

export function useOrdersHistory() {
  const [ordersHistory, setOrdersHistory] = useLocalStorage(ORDERS_KEY, [])

  const onAddToOrdersHistory = async (orderHistory: any) => {
    if (!orderHistory) return
    setOrdersHistory([orderHistory, ...ordersHistory])
  }

  return [ordersHistory, onAddToOrdersHistory] as Array<any>
}
