import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FAQ } from '../../../components/Answers/QA'
import { useHistory } from 'react-router-dom'
import Template from '../../Template'

type Props = {
  match: {
    params: {
      shopUrl: string
      id: string
    }
  }
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    margin: '70px 40px',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
  },
  question: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
  },
  links: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    textDecoration: 'underline',
    marginRight: 'auto',
    cursor: 'pointer',
  },
}))

function Answer({
  match: {
    params: { shopUrl, id },
  },
}: Props) {
  const classes = useStyles()
  const history = useHistory()
  const handleBackClick = () => {
    history.goBack()
  }
  return (
    <Template shopUrl={shopUrl}>
      <div className={classes.container}>
        <span className={classes.links} onClick={handleBackClick}>
          Back
        </span>
        <h2 className={classes.title}>{FAQ[id].question}</h2>
        {FAQ[id] ? FAQ[id].answer : <div>No content available</div>}
      </div>
    </Template>
  )
}

export default Answer
