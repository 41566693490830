import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircle } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import parse from 'html-react-parser'

type Props = {
  steps: string[]
  status: number
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 25,
    paddingTop: 0,
  },
  stepContainer: {
    paddingTop: 15,
    // paddingLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  stepText: {
    fontSize: 14,
    fontFamily: 'Nunito',
    lineHeight: '19px',
    letterSpacing: '-0.02em',
    color: ' #999999',
    textAlign: 'center',
    maxWidth: 300,
    paddingLeft: 14,
  },
  stepIcon: {
    paddingLeft: 2,
    paddingRight: 2,
  },
}))

const StepsLoader = ({ steps, status }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {steps.map((step, index) => (
          <div className={classes.stepContainer} key={index}>
            <div className={classes.stepIcon}>
              {index < status ? (
                <CheckCircle color="primary" />
              ) : (
                <CircularProgress size={20} thickness={4} color="primary" />
              )}
            </div>
            <span className={classes.stepText}>{parse(step)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StepsLoader
