import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  blink: {
    animation: '1s $blink infinite',
    '&:nth-child(1)': {
      animationDelay: '0ms',
    },
    '&:nth-child(2)': {
      animationDelay: '250ms',
    },
    '&:nth-child(3)': {
      animationDelay: '500ms',
    },
  },
  '@keyframes blink': {
    '50%': {
      color: 'transparent',
    },
  },
}))

const Blink = ({ children }: { children: any }) => {
  const classes = useStyles()
  return <span className={classes.blink}>{children}</span>
}

export default Blink
