import { useQuery } from '@apollo/react-hooks'
import GenerateDiscountCode from './graphql/GenerateDiscountCode'

type GenerateDiscounntCodeInput = {
  settingsId?: string
  userId?: string
  selectedCountry?: string
}
export function useGenerateDiscountCode({
  settingsId,
  userId,
  selectedCountry,
}: GenerateDiscounntCodeInput) {
  const { data, loading, error } = useQuery(GenerateDiscountCode, {
    variables: {
      input: {
        settingsId,
        userId,
        shippingCountry: selectedCountry,
      },
    },
  })

  return { data, loading, error }
}

export default useGenerateDiscountCode
