import useLocalStorage from 'react-use/lib/useLocalStorage'

const FIRST_TIME_KEY = 'myshop-first-time'

export function useFirstTime(shopUrl: string) {
  const [isFirstTime, setFirstTime] = useLocalStorage(
    `${FIRST_TIME_KEY}-${shopUrl}`,
    true,
  )

  return [isFirstTime, setFirstTime] as Array<any>
}
