import React from 'react'

export const LikeHeart = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.965 19L10.2609 18.4309C6.06008 15.0373 3.52345 12.9911 2.07964 10.3194C1.01671 8.35855 0.732155 6.42425 1.25476 4.72689C1.69322 3.30744 2.68085 2.16368 4.0383 1.50821C4.73695 1.17051 5.4954 1 6.29481 1C8.19922 1 9.9918 1.99428 10.965 3.53552C11.9383 1.99428 13.7331 1 15.6353 1C16.4347 1 17.1931 1.17051 17.8907 1.5071C19.2492 2.16368 20.2369 3.30744 20.6753 4.72689C21.1979 6.42425 20.9123 8.35855 19.8504 10.3194C18.4055 12.9922 15.8667 15.0417 11.6604 18.4375L10.965 19Z"
      stroke="black"
      strokeOpacity="0.5"
      strokeWidth="1.2"
    />
  </svg>
)

export const LikeRedHeart = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.96504 18L9.26085 17.4309C5.06008 14.0373 2.52345 11.9911 1.07964 9.31943C0.0167094 7.35855 -0.267845 5.42425 0.254761 3.72689C0.693218 2.30744 1.68085 1.16368 3.0383 0.508212C3.73695 0.170511 4.4954 0 5.29481 0C7.19922 0 8.9918 0.994279 9.96504 2.53552C10.9383 0.994279 12.7331 0 14.6353 0C15.4347 0 16.1931 0.170511 16.8907 0.507105C18.2492 1.16368 19.2369 2.30744 19.6753 3.72689C20.1979 5.42425 19.9123 7.35855 18.8504 9.31943C17.4055 11.9922 14.8667 14.0417 10.6604 17.4375L9.96504 18Z"
      fill="#F6716D"
    />
  </svg>
)
