import { createMuiTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

// Create a theme instance.
const theme = createMuiTheme({
  styles: {
    button: {
      height: 40,
      textTransform: 'capitalize',
      color: '#262626',
      background: '#ffffff',
      boxShadow: 'none',
      borderRadius: 4,
      border: 'unset',
      // border: '1px solid rgba(0, 0, 0, 0.2)',
      '&:hover': {
        color: '#262626',
        background: '#ffffff',
        boxShadow: 'none',
      },
    },
    blueButton: {
      fontFamily: 'Helvetica',
      fontSize: 15,
      lineHeight: '16px',
      background: '#3F9AF1',
      borderColor: '#3F9AF1',
      color: '#FBFBFB',
      '&:hover': {
        color: '#FBFBFB',
        background: '#3F9AF1',
        boxShadow: 'none',
      },
    },
    blackButton: {
      fontFamily: 'Helvetica',
      fontSize: 15,
      lineHeight: '16px',
      background: '#000000',
      borderColor: '#000000',
      color: '#FBFBFB',
      '&:hover': {
        color: '#FBFBFB',
        background: '#000000',
        boxShadow: 'none',
      },
    },
    grayButton: {
      fontFamily: 'Helvetica',
      fontSize: 15,
      lineHeight: '16px',
      background: '#EEEEEE',
      borderColor: '#E6E7E8',
      color: '#000000',
      '&:hover': {
        color: '#FBFBFB',
        background: '#EEEEEE',
        boxShadow: 'none',
      },
    },
    textField: {
      marginBottom: 10,
      background: '#dfdede38',
      borderRadius: 10,
      boxSizing: 'border-box',
      height: 50,
    },
    input: {
      borderRadius: 10,
      boxSizing: 'border-box',
      height: 50,
    },
    shadow: '1px 1px 1px 0px rgba(0,0,0,0.2)',
  },
  palette: {
    standard: '#262626',
    gray: '#AAAAAA',
    green: '#039D2E',
    red: 'rgba(246, 113, 109, 0.8)',
    white: '#FFFFFF',
    blue: '#3F9AF1',
    primary: {
      main: '#3F9AF1',
    },
    secondary: {
      main: '#262626',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#FAFAFA',
    },
  },
} as any)

export default theme
