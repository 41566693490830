import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FAQ } from '../../components/Answers/QA'
import { useHistory } from 'react-router-dom'

import Template from '../Template'

type Props = {
  match: {
    params: {
      shopUrl: string
    }
  }
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    margin: '70px 40px',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
    'text-decoration': 'underline',
  },
  question: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    'text-decoration': 'underline',
    cursor: 'pointer',
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    paddingBottom: 20,
    color: '#999999',
  },
}))

function Question(props: {
  id: string
  question: string
  onClick: (e: any) => void
  key: any
}) {
  const classes = useStyles()
  const { id, question, onClick } = props
  return (
    <p id={id} className={classes.question} onClick={onClick}>
      {' '}
      {question}{' '}
    </p>
  )
}

function Faq({
  match: {
    params: { shopUrl },
  },
}: Props) {
  const classes = useStyles()
  const history = useHistory()
  const handleQuestionClick = e => {
    history.push(`/faq/${e.target.id}`)
  }

  const ids = Object.keys(FAQ)
  return (
    <Template shopUrl={shopUrl}>
      <div className={classes.container}>
        <h2 className={classes.title}>Frequently Asked Questions</h2>
        {ids.map((id, idx) => (
          <Question
            key={idx}
            id={id}
            question={FAQ[id].question}
            onClick={handleQuestionClick}
          />
        ))}
      </div>
    </Template>
  )
}

export default Faq
