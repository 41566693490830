import React from 'react'
import {
  BuySomething,
  ContactSeller,
  TrackerOrders,
  OrderConfirmation,
  CancelOrder,
  OrderConfirmationEmail,
  HowItWorks,
  ConnectMyFeed,
  SafeToBuy,
} from './Answers'

export const FAQ = {
  '1': {
    question: 'How do I buy something through MyShop?',
    answer: <BuySomething></BuySomething>,
  },
  '2': {
    question: 'How do I contact the seller or MyShop for help with my order?',
    answer: <ContactSeller></ContactSeller>,
  },
  '3': {
    question: 'How can I track orders I made through MyShop?',
    answer: <TrackerOrders></TrackerOrders>,
  },
  '4': {
    question:
      'Where can I see the order confirmation for something I purchased through MyShop?',
    answer: <OrderConfirmation></OrderConfirmation>,
  },
  '5': {
    question: 'How do I cancel or change an order I made through MyShop?',
    answer: <CancelOrder></CancelOrder>,
  },
  '6': {
    question: 'I did not get an order confirmation email, what do I do?',
    answer: <OrderConfirmationEmail></OrderConfirmationEmail>,
  },
  '7': {
    question: 'What is MyShop and how does it work?',
    answer: <HowItWorks></HowItWorks>,
  },
  '8': {
    question: 'Is it safe to buy products through MyShop?',
    answer: <SafeToBuy></SafeToBuy>,
  },
  '9': {
    question:
      'How can I connect my webshop product feed to the MyShop catalog?',
    answer: <ConnectMyFeed></ConnectMyFeed>,
  },
}
