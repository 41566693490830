import { gql } from 'apollo-boost'

const UnLikeProduct = gql`
  mutation unlikeProduct($input: LikeInput!) {
    unlikeProduct(input: $input) {
      id
      likes
    }
  }
`

export default UnLikeProduct
