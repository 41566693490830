import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Template from '../Template'

type Props = {
  match: {
    params: {
      shopUrl: string
    }
  }
}
const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    margin: '70px 40px',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    letterSpacing: '-0.02em',
  },
  h3: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    letterSpacing: '-0.02em',
    fontWeight: 'bold',
  },
  link: {
    color: '#999999',
  },
}))

function TermsAndConditions({
  match: {
    params: { shopUrl },
  },
}: Props) {
  const classes = useStyles()
  return (
    <Template shopUrl={shopUrl}>
      <div className={classes.container}>
        <h2 className={classes.title}>Terms and conditions</h2>

        <h3 className={classes.h3}>1. INTRODUCTION</h3>
        <p className={classes.text}>
          Please read these terms of sale carefully. You will be asked to
          expressly agree to these terms of sale before you place an order for
          products through MyShop..
        </p>

        <h3 className={classes.h3}>2. INTERPRETATION</h3>
        <p className={classes.text}>
          In these terms of sale, &quot;we&quot;, &quot;us&quot; and
          &quot;our&quot; means MyShop.io a subsidiary of Brandbassador AS and
          &quot;you&quot; means our customer or potential customer for products
          (and &quot;your&quot; will be construed accordingly).
        </p>
        <p className={classes.text}>
          The entity responsible for your purchases on our website and the
          processing of your personal data will depend on how you interact with
          our site and where you are located in the world.
        </p>
        <h3 className={classes.h3}>3. ORDER PROCESS</h3>
        <p className={classes.text}>
          The advertising of products on our website constitutes an
          &quot;invitation to treat&quot;; and your order for products does not
          constitute a contractual offer between us and you. We are only a
          facilitator of the order and no contract will come into force between
          you and us by placing an order through MyShop platform. Any order
          placed through our platform will create a contract with one or more of
          our merchant partners and you are required to familiarize yourself
          with the Terms & Conditions and Privacy Policy of any of our Merchant
          Partners selling products through MyShop.
        </p>
        <p className={classes.text}>
          You must add the products you wish to purchase to your shopping cart,
          and then proceed to the checkout. You will choose the appropriate
          payment provider who will handle your payment. You must select your
          preferred method of delivery if provided. You have the option to opt
          in to receive our newsletters, giving you access to exclusive offers
          and new product details from us and our Merchant Partners. You can
          easily cancel the subscription at any time by using the unsubscribe
          link at the bottom of our newsletters. We will never sell any of your
          personal information to any third parties. We keep all our data safe
          and secure. You must confirm your order and your consent to these
          terms of sale. The only languages in which we provide these terms of
          sale are English. Before you place your order, you will not have the
          opportunity of identifying whether you have made any input errors by
          viewing the order on the summary page. You may there want to quality
          control any inputs before completing your order. Once we have checked
          with our merchant partner whether they are able to meet your order,
          they will either send you an order confirmation (at which point your
          order will become a binding contract with the Merchant Partner) or we
          will confirm by an onscreen message that the Merchant Partner is
          unable to meet your order. We will not file a copy of these terms and
          conditions specifically in relation to your order. We may update the
          version of these terms and conditions on the website from time to
          time, and we do not guarantee that the version you have agreed to will
          remain accessible. We therefore recommend that you download/copy,
          print and retain a copy of these terms and conditions for your
          records.
        </p>

        <h3 className={classes.h3}>4. PRICE AND PAYMENT</h3>
        <p className={classes.text}>
          Prices for products are quoted on our website. The website contains a
          large number of products and it is always possible that some of the
          prices on the website may be incorrect. We will verify prices as part
          of our sale procedures so that a product&apos;s correct price will be
          stated when you pay for the product. In addition to the price of the
          products, you will/may have to pay a delivery charge, which will be as
          stated when you pay for the product. For now all delivery charges are
          free on MyShop.io. Prices do not include: taxes, duties, import fees
          charged by your local government, service, payment or delivery charges
          depending on the country and the Merchant Partner. You are required to
          find out what is included in the price based on the Merchants Terms
          and Conditions. The prices on the website include all value added
          taxes (where applicable) if stated in the Terms and Conditions of the
          respective Merchant Partner. Payment must be made upon the submission
          of your order. Prices for products are liable to change at any time.
        </p>

        <h3 className={classes.h3}>5. YOUR WARRANTIES</h3>
        <p className={classes.text}>
          You warrant to us that: You are legally capable of entering into
          binding contracts, and you have full authority, power and capacity to
          agree to these terms of Conditions. The information provided in your
          order is accurate and complete You will be able to accept delivery of
          the products You are a resident in the country within which you choose
          to receive your order You are at least 18 years of age
        </p>

        <h3 className={classes.h3}>6. DELIVERY POLICY</h3>
        <p className={classes.text}>
          MyShop is in no way responsible for the delivery of products purchased
          through the MyShop platform. Please familiarise yourself with each
          Merchant Partners specific delivery policy before completing your
          purchase.
        </p>

        <h3 className={classes.h3}>7. RISK AND OWNERSHIP</h3>
        <p className={classes.text}>
          The products will be at your risk from the time of delivery. Ownership
          of the products will only pass to you upon delivery of the products
          and receipt by us of full payment of all sums due in respect of the
          products (including delivery charges). We will be entitled to recover
          payment for the products even where ownership has not been passed to
          you.
        </p>

        <h3 className={classes.h3}>8. &quot;COOLING OFF&quot; PERIOD</h3>
        <p className={classes.text}>
          Under the Distance Selling Regulations, you may cancel a distance
          contract to purchase a product or products from any of our Merchants
          Partners at any time within 7 working days after the day you received
          the relevant product or products (subject to the limitations set out
          below). In order to cancel a contract in this way, you must give the
          Merchant Partner written notice of cancellation. You will not have any
          such right insofar as a contract relates to: The supply of products
          the price of which is dependent upon fluctuations in financial markets
          which we cannot control The supply of newspapers, periodicals or
          magazines The supply of goods made to your specifications or clearly
          personalised The supply of goods which by reason of their nature
          cannot be returned or are liable to deteriorate or expire rapidly If
          you cancel a contract on this basis, you must promptly return the
          products to the respective Merchant Partner, in the same condition in
          which you received them, including labels and packaging. If you cancel
          a contract on this basis, you will be refunded in full (Merchant
          Partners have different practice in regards to refund of the shipping
          cost and the return cost. Please familiarize yourself with the
          Merchant Partners Terms and Conditions.)
        </p>

        <h3 className={classes.h3}>9. STATUTORY RIGHTS</h3>
        <p className={classes.text}>
          Nothing in these terms of sale affects your statutory rights
          (including your right to receive a refund in respect of any defective
          product our Merchant Partners sell to you).
        </p>

        <h3 className={classes.h3}>10. REFUNDS</h3>
        <p className={classes.text}>
          If you cancel a contract and are entitled to a refund, the Merchant
          Partner will usually refund any money received from you using the same
          method originally used by you to pay for your purchase. The Merchant
          Partner will process the refund due to you as soon as possible and, in
          any event within 30 days of the day we received your valid notice of
          cancellation.
        </p>

        <h3 className={classes.h3}>
          11. LIMITATIONS AND EXCLUSIONS OF LIABILITY
        </h3>
        <p className={classes.text}>
          Nothing in the terms of sale will: Limit or exclude the liability of a
          party for death or personal injury resulting from negligence Limit or
          exclude the liability of a party for fraud or fraudulent
          misrepresentation by that party Limit or exclude any liability of a
          party under Section 12, 13, 14 or 15 of the Sale of Goods Act 1979 or
          Part I of the Consumer Protection Act 1987 (delete) Limit any
          liability of a party in any way that is not permitted under applicable
          law Exclude any liability of a party that may not be excluded under
          applicable law Any statutory rights which you have as a consumer,
          which cannot be excluded or limited, will not be affected by the terms
          of sale. The limitations and exclusions of liability set out in this
          Section and elsewhere in the terms of sale are subject to the
          preceding paragraph and govern all liabilities arising under the terms
          of sale or in relation to the subject matter of the terms of sale,
          including liabilities arising in contract, in tort (including
          negligence) and for breach of statutory duty. We will not be liable to
          you in respect of any losses arising out of any event or series of
          events beyond our reasonable control. We will not be liable to you in
          respect of any business losses, such as loss of or damage to profits,
          income, revenue, use, production, anticipated savings, business,
          contracts, commercial opportunities or goodwill.
        </p>

        <h3 className={classes.h3}>12. GENERAL TERMS & PRIVACY POLICY</h3>
        <p className={classes.text}>
          We will treat all your personal information that we collect in
          connection with your order in accordance with the terms of our privacy
          policy. Use of our website will be subject to our website terms of
          use. Contracts under these terms of sale may only be varied by an
          instrument in writing signed by both you and us. We may revise these
          terms of sale from time-to-time, but such revisions will not affect
          the terms of any contracts which we have entered into with you. If any
          provision of these terms of sale is held invalid or unenforceable by a
          court of competent jurisdiction, the remaining provisions will remain
          in full force and effect, and such invalid or unenforceable provisions
          or portion thereof will be deemed omitted. No waiver of any provision
          of these terms of sale, whether by conduct or otherwise, in any one or
          more instances, will be deemed to be, or be construed as, a further or
          continuing waiver of that provision or any other provision of these
          terms of sale. You may not assign, charge, sub-contract or otherwise
          transfer any of your rights or obligations arising under these terms
          of sale. Any attempt by you to do so will be null and void. We may
          assign, charge, sub-contract or otherwise transfer any of our rights
          or obligations arising under these terms of sale, at any time
          providing such action does not serve to reduce the guarantees
          benefiting you under these terms of sale. Each contract under these
          terms of sale is made for the benefit of the parties to it and is not
          intended to benefit, or be enforceable by, any other person. The right
          of the parties to terminate, rescind, or agree any amendment,
          variation, waiver or settlement under such contracts is not subject to
          the consent of any person who is not a party to the relevant contract.
          Subject to the first paragraph of Section 12: these terms of sale and
          the terms of sale from the respective Merchant Partner contain the
          entire agreement and understanding of the parties in relation to the
          purchase of products from our website and supersede all previous
          agreements and understandings between the parties in relation to the
          purchase of products from our website; and each party acknowledges
          that no representations not expressly contained in these terms of sale
          have been made by or on behalf of the other party in relation to the
          purchase of products from our website. These terms of sale will be
          governed by and construed in accordance with applicable laws, and the
          Merchant Partners entity responsible for your sale will have
          non-exclusive jurisdiction to adjudicate any dispute arising under or
          in relation to these terms of sale.
        </p>

        <h3 className={classes.h3}>13. DISCLAIMERS</h3>
        <p className={classes.text}>
          This website is intended for promotional and advertising purposes and
          you can use it only for private, personal and non-commercial use. The
          website may contain links to other websites which are not under our
          control. These terms and conditions are to be intended only for this
          website. We (or our employees, officers, directors and shareholders)
          shall not be deemed responsible for the contents or the non security
          of said third websites or for viruses or other negative consequences
          arising from the visit of those third websites. This website is
          provided &quot;as is&quot; and we make no warranties of the absence of
          malicious programs (such as viruses, malware, bugs, Trojan, etc.). We
          definitely encourage you to install one or more antivirus programs to
          protect your computer. We (or our employees, officers, directors and
          shareholders) shall not be responsible or liable for any damages of
          any kind whatsoever arising out of, caused by or related to your use
          of this website. We do not represent or warrant that the information
          contained in this website is accurate and complete. If the law
          currently in force does not allow the exclusion of implied warranties,
          the cases of warranties’ exclusions set forth in this paragraph will
          not be applied, in whole or in part to you. This website may contain
          comments or images with ironical, humorous or alluring content in line
          with the brand message, but is in no case intended to offend anyone’s
          sensitivity. If you don’t appreciate them please do not visit the
          website. This website is not for children and if you are under 18
          years of age, please exit the website or ask your parent and
          guardian&apos;s permission. We are opposed to human trafficking, child
          labor, slavery and sexual discrimination and we possess internal
          standards and procedures to ensure that these illegal behaviors are
          not incorporated into our supply chain.
        </p>

        <h3 className={classes.h3}>14. ABOUT US</h3>
        <p className={classes.text}>
          Our registered office and principal trading address is: MyShop.io a
          subsidiary of Brandbassador AS, Eilert Sundts Gate 41, 0355 Oslo,
          Norway. Our email address is{' '}
          <a className={classes.link} href="mailto:hello@brandbassador.com">
            hello@brandbassador.com
          </a>
          <br />
          <br />
          15.09.2020
        </p>
      </div>
    </Template>
  )
}

export default TermsAndConditions
