class CustomError extends Error {
  private code: string
  constructor(code, message) {
    super('No property: ' + code)
    this.name = 'CustomError'
    this.code = code
    this.message = message
  }
}

export default CustomError
