import { Country } from '../types'

const isoCountries = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua And Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia And Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, Democratic Republic',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote D'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island & Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic Of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle Of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States Of',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts And Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre And Miquelon',
  VC: 'Saint Vincent And Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome And Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia And Sandwich Isl.',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard And Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad And Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks And Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis And Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}

const provinceData = {
  AR: {
    name: 'Argentina',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Buenos Aires',
      'Catamarca',
      'Chaco',
      'Chubut',
      'Ciudad Autónoma de Buenos Aires',
      'Córdoba',
      'Corrientes',
      'Entre Ríos',
      'Formosa',
      'Jujuy',
      'La Pampa',
      'La Rioja',
      'Mendoza',
      'Misiones',
      'Neuquén',
      'Río Negro',
      'Salta',
      'San Juan',
      'San Luis',
      'Santa Cruz',
      'Santa Fe',
      'Santiago Del Estero',
      'Tierra Del Fuego',
      'Tucumán',
    ],
  },
  AU: {
    name: 'Australia',
    provinceLabel: 'State/territory',
    zipRequired: true,
    provinces: [
      'Australian Capital Territory',
      'New South Wales',
      'Northern Territory',
      'Queensland',
      'South Australia',
      'Tasmania',
      'Victoria',
      'Western Australia',
    ],
  },
  BR: {
    name: 'Brazil',
    provinceLabel: 'State',
    zipRequired: true,
    provinces: [
      'Acre',
      'Alagoas',
      'Amapá',
      'Amazonas',
      'Bahia',
      'Ceará',
      'Distrito Federal',
      'Espírito Santo',
      'Goiás',
      'Maranhão',
      'Mato Grosso',
      'Mato Grosso do Sul',
      'Minas Gerais',
      'Pará',
      'Paraíba',
      'Paraná',
      'Pernambuco',
      'Piauí',
      'Rio Grande do Norte',
      'Rio Grande do Sul',
      'Rio de Janeiro',
      'Rondônia',
      'Roraima',
      'Santa Catarina',
      'São Paulo',
      'Sergipe',
      'Tocantins',
    ],
  },
  CA: {
    name: 'Canada',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
      'Yukon',
    ],
  },
  CN: {
    name: 'China',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Anhui',
      'Beijing',
      'Chongqing',
      'Fujian',
      'Gansu',
      'Guangdong',
      'Guangxi',
      'Guizhou',
      'Hainan',
      'Hebei',
      'Heilongjiang',
      'Henan',
      'Hubei',
      'Hunan',
      'Inner Mongolia',
      'Jiangsu',
      'Jiangxi',
      'Jilin',
      'Liaoning',
      'Ningxia',
      'Qinghai',
      'Shaanxi',
      'Shandong',
      'Shanghai',
      'Shanxi',
      'Sichuan',
      'Tianjin',
      'Xinjiang',
      'Xizang',
      'Yunnan',
      'Zhejiang',
    ],
  },
  CO: {
    name: 'Colombia',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Amazonas',
      'Antioquia',
      'Arauca',
      'Atlántico',
      'Bogotá, D.C.',
      'Bolívar',
      'Boyacá',
      'Caldas',
      'Caquetá',
      'Casanare',
      'Cauca',
      'Cesar',
      'Chocó',
      'Córdoba',
      'Cundinamarca',
      'Guainía',
      'Guaviare',
      'Huila',
      'La Guajira',
      'Magdalena',
      'Meta',
      'Nariño',
      'Norte de Santander',
      'Putumayo',
      'Quindío',
      'Risaralda',
      'San Andrés, Providencia y Santa Catalina',
      'Santander',
      'Sucre',
      'Tolima',
      'Valle del Cauca',
      'Vaupés',
      'Vichada',
    ],
  },
  EG: {
    name: 'Egypt',
    provinceLabel: 'Governorate',
    zipRequired: true,
    provinces: [
      '6th of October',
      'Al Sharqia',
      'Alexandria',
      'Aswan',
      'Asyut',
      'Beheira',
      'Beni Suef',
      'Cairo',
      'Dakahlia',
      'Damietta',
      'Faiyum',
      'Gharbia',
      'Giza',
      'Helwan',
      'Ismailia',
      'Kafr el-Sheikh',
      'Luxor',
      'Matrouh',
      'Minya',
      'Monufia',
      'New Valley',
      'North Sinai',
      'Port Said',
      'Qalyubia',
      'Qena',
      'Red Sea',
      'Sohag',
      'South Sinai',
      'Suez',
    ],
  },
  GT: {
    name: 'Guatemala',
    provinceLabel: 'Region',
    zipRequired: true,
    provinces: [
      'Alta Verapaz',
      'Baja Verapaz',
      'Chimaltenango',
      'Chiquimula',
      'El Progreso',
      'Escuintla',
      'Guatemala',
      'Huehuetenango',
      'Izabal',
      'Jalapa',
      'Jutiapa',
      'Petén',
      'Quetzaltenango',
      'Quiché',
      'Retalhuleu',
      'Sacatepéquez',
      'San Marcos',
      'Santa Rosa',
      'Sololá',
      'Suchitepéquez',
      'Totonicapán',
      'Zacapa',
    ],
  },
  HK: {
    name: 'Hong Kong',
    provinceLabel: 'Region',
    zipRequired: false,
    provinces: ['Hong Kong Island', 'Kowloon', 'New Territories'],
  },
  IN: {
    name: 'India',
    provinceLabel: 'State',
    zipRequired: true,
    provinces: [
      'Andaman and Nicobar',
      'Andhra Pradesh',
      'Arunachal Pradesh',
      'Assam',
      'Bihar',
      'Chandigarh',
      'Chattisgarh',
      'Dadra and Nagar Haveli',
      'Daman and Diu',
      'Delhi',
      'Goa',
      'Gujarat',
      'Haryana',
      'Himachal Pradesh',
      'Jammu and Kashmir',
      'Jharkhand',
      'Karnataka',
      'Kerala',
      'Lakshadweep',
      'Madhya Pradesh',
      'Maharashtra',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'Orissa',
      'Puducherry',
      'Punjab',
      'Rajasthan',
      'Sikkim',
      'Tamil Nadu',
      'Telangana',
      'Tripura',
      'Uttar Pradesh',
      'Uttarakhand',
      'West Bengal',
    ],
  },
  ID: {
    name: 'Indonesia',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Aceh',
      'Bali',
      'Bangka Belitung',
      'Banten',
      'Bengkulu',
      'Gorontalo',
      'Jakarta',
      'Jambi',
      'Jawa Barat',
      'Jawa Tengah',
      'Jawa Timur',
      'Kalimantan Barat',
      'Kalimantan Selatan',
      'Kalimantan Tengah',
      'Kalimantan Timur',
      'Kalimantan Utara',
      'Kepulauan Riau',
      'Lampung',
      'Maluku',
      'Maluku Utara',
      'Nusa Tenggara Barat',
      'Nusa Tenggara Timur',
      'Papua',
      'Papua Barat',
      'Riau',
      'Sulawesi Barat',
      'Sulawesi Selatan',
      'Sulawesi Tengah',
      'Sulawesi Tenggara',
      'Sulawesi Utara',
      'Sumatra Barat',
      'Sumatra Selatan',
      'Sumatra Utara',
      'Yogyakarta',
    ],
  },
  IE: {
    name: 'Ireland',
    provinceLabel: 'County',
    zipRequired: true,
    provinces: [
      'Carlow',
      'Cavan',
      'Clare',
      'Cork',
      'Donegal',
      'Dublin',
      'Galway',
      'Kerry',
      'Kildare',
      'Kilkenny',
      'Laois',
      'Leitrim',
      'Limerick',
      'Longford',
      'Louth',
      'Mayo',
      'Meath',
      'Monaghan',
      'Offaly',
      'Roscommon',
      'Sligo',
      'Tipperary',
      'Waterford',
      'Westmeath',
      'Wexford',
      'Wicklow',
    ],
  },
  IT: {
    name: 'Italy',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Agrigento',
      'Alessandria',
      'Ancona',
      'Aosta',
      'Arezzo',
      'Ascoli Piceno',
      'Asti',
      'Avellino',
      'Bari',
      'Barletta-Andria-Trani',
      'Belluno',
      'Benevento',
      'Bergamo',
      'Biella',
      'Bologna',
      'Bolzano',
      'Brescia',
      'Brindisi',
      'Cagliari',
      'Caltanissetta',
      'Campobasso',
      'Carbonia-Iglesias',
      'Caserta',
      'Catania',
      'Catanzaro',
      'Chieti',
      'Como',
      'Cosenza',
      'Cremona',
      'Crotone',
      'Cuneo',
      'Enna',
      'Fermo',
      'Ferrara',
      'Firenze',
      'Foggia',
      'Forlì-Cesena',
      'Frosinone',
      'Genova',
      'Gorizia',
      'Grosseto',
      'Imperia',
      'Isernia',
      "L'Aquila",
      'La Spezia',
      'Latina',
      'Lecce',
      'Lecco',
      'Livorno',
      'Lodi',
      'Lucca',
      'Macerata',
      'Mantova',
      'Massa-Carrara',
      'Matera',
      'Medio Campidano',
      'Messina',
      'Milano',
      'Modena',
      'Monza e Brianza',
      'Napoli',
      'Novara',
      'Nuoro',
      'Ogliastra',
      'Olbia-Tempio',
      'Oristano',
      'Padova',
      'Palermo',
      'Parma',
      'Pavia',
      'Perugia',
      'Pesaro e Urbino',
      'Pescara',
      'Piacenza',
      'Pisa',
      'Pistoia',
      'Pordenone',
      'Potenza',
      'Prato',
      'Ragusa',
      'Ravenna',
      'Reggio Calabria',
      'Reggio Emilia',
      'Rieti',
      'Rimini',
      'Roma',
      'Rovigo',
      'Salerno',
      'Sassari',
      'Savona',
      'Siena',
      'Siracusa',
      'Sondrio',
      'Taranto',
      'Teramo',
      'Terni',
      'Torino',
      'Trapani',
      'Trento',
      'Treviso',
      'Trieste',
      'Udine',
      'Varese',
      'Venezia',
      'Verbano-Cusio-Ossola',
      'Vercelli',
      'Verona',
      'Vibo Valentia',
      'Vicenza',
      'Viterbo',
    ],
  },
  JP: {
    name: 'Japan',
    provinceLabel: 'Prefecture',
    zipRequired: true,
    provinces: [
      'Hokkaidō',
      'Aomori',
      'Iwate',
      'Miyagi',
      'Akita',
      'Yamagata',
      'Fukushima',
      'Ibaraki',
      'Tochigi',
      'Gunma',
      'Saitama',
      'Chiba',
      'Tōkyō',
      'Kanagawa',
      'Niigata',
      'Toyama',
      'Ishikawa',
      'Fukui',
      'Yamanashi',
      'Nagano',
      'Gifu',
      'Shizuoka',
      'Aichi',
      'Mie',
      'Shiga',
      'Kyōto',
      'Ōsaka',
      'Hyōgo',
      'Nara',
      'Wakayama',
      'Tottori',
      'Shimane',
      'Okayama',
      'Hiroshima',
      'Yamaguchi',
      'Tokushima',
      'Kagawa',
      'Ehime',
      'Kōchi',
      'Fukuoka',
      'Saga',
      'Nagasaki',
      'Kumamoto',
      'Ōita',
      'Miyazaki',
      'Kagoshima',
      'Okinawa',
    ],
  },
  MY: {
    name: 'Malaysia',
    provinceLabel: 'State/territory',
    zipRequired: true,
    provinces: [
      'Johor',
      'Kedah',
      'Kelantan',
      'Kuala Lumpur',
      'Labuan',
      'Melaka',
      'Negeri Sembilan',
      'Pahang',
      'Perak',
      'Perlis',
      'Pulau Pinang',
      'Putrajaya',
      'Sabah',
      'Sarawak',
      'Selangor',
      'Terengganu',
    ],
  },
  MX: {
    name: 'Mexico',
    provinceLabel: 'State',
    zipRequired: true,
    provinces: [
      'Aguascalientes',
      'Baja California',
      'Baja California Sur',
      'Campeche',
      'Chiapas',
      'Chihuahua',
      'Ciudad de México',
      'Coahuila',
      'Colima',
      'Durango',
      'Guanajuato',
      'Guerrero',
      'Hidalgo',
      'Jalisco',
      'México',
      'Michoacán',
      'Morelos',
      'Nayarit',
      'Nuevo León',
      'Oaxaca',
      'Puebla',
      'Querétaro',
      'Quintana Roo',
      'San Luis Potosí',
      'Sinaloa',
      'Sonora',
      'Tabasco',
      'Tamaulipas',
      'Tlaxcala',
      'Veracruz',
      'Yucatán',
      'Zacatecas',
    ],
  },
  NZ: {
    name: 'New Zealand',
    provinceLabel: 'Region',
    zipRequired: true,
    provinces: [
      'Auckland',
      'Bay of Plenty',
      'Canterbury',
      'Gisborne',
      "Hawke's Bay",
      'Manawatu-Wanganui',
      'Marlborough',
      'Nelson',
      'Northland',
      'Otago',
      'Southland',
      'Taranaki',
      'Tasman',
      'Waikato',
      'Wellington',
      'West Coast',
    ],
  },
  NG: {
    name: 'Nigeria',
    provinceLabel: 'State',
    zipRequired: true,
    provinces: [
      'Abia',
      'Abuja Federal Capital Territory',
      'Adamawa',
      'Akwa Ibom',
      'Anambra',
      'Bauchi',
      'Bayelsa',
      'Benue',
      'Borno',
      'Cross River',
      'Delta',
      'Ebonyi',
      'Edo',
      'Ekiti',
      'Enugu',
      'Gombe',
      'Imo',
      'Jigawa',
      'Kaduna',
      'Kano',
      'Katsina',
      'Kebbi',
      'Kogi',
      'Kwara',
      'Lagos',
      'Nasarawa',
      'Niger',
      'Ogun',
      'Ondo',
      'Osun',
      'Oyo',
      'Plateau',
      'Rivers',
      'Sokoto',
      'Taraba',
      'Yobe',
      'Zamfara',
    ],
  },
  PA: {
    name: 'Panama',
    provinceLabel: 'Region',
    zipRequired: false,
    provinces: [
      'Bocas del Toro',
      'Chiriquí',
      'Coclé',
      'Colón',
      'Darién',
      'Emberá',
      'Herrera',
      'Kuna Yala',
      'Los Santos',
      'Ngöbe-Buglé',
      'Panamá',
      'Panamá Oeste',
      'Veraguas',
    ],
  },
  PE: {
    name: 'Peru',
    provinceLabel: 'Region',
    zipRequired: true,
    provinces: [
      'Amazonas',
      'Áncash',
      'Apurímac',
      'Arequipa',
      'Ayacucho',
      'Cajamarca',
      'Callao',
      'Cuzco',
      'Huancavelica',
      'Huánuco',
      'Ica',
      'Junín',
      'La Libertad',
      'Lambayeque',
      'Lima (departamento)',
      'Lima (provincia)',
      'Loreto',
      'Madre de Dios',
      'Moquegua',
      'Pasco',
      'Piura',
      'Puno',
      'San Martín',
      'Tacna',
      'Tumbes',
      'Ucayali',
    ],
  },
  PT: {
    name: 'Portugal',
    provinceLabel: 'Region',
    zipRequired: true,
    provinces: [
      'Açores',
      'Aveiro',
      'Beja',
      'Braga',
      'Bragança',
      'Castelo Branco',
      'Coimbra',
      'Évora',
      'Faro',
      'Guarda',
      'Leiria',
      'Lisboa',
      'Madeira',
      'Portalegre',
      'Porto',
      'Santarém',
      'Setúbal',
      'Viana do Castelo',
      'Vila Real',
      'Viseu',
    ],
  },
  RO: {
    name: 'Romania',
    provinceLabel: 'County',
    zipRequired: true,
    provinces: [
      'Alba',
      'Arad',
      'Argeș',
      'Bacău',
      'Bihor',
      'Bistrița-Năsăud',
      'Botoșani',
      'Brașov',
      'Brăila',
      'București',
      'Buzău',
      'Călărași',
      'Caraș-Severin',
      'Cluj',
      'Constanța',
      'Covasna',
      'Dâmbovița',
      'Dolj',
      'Galați',
      'Giurgiu',
      'Gorj',
      'Harghita',
      'Hunedoara',
      'Iași',
      'Ialomița',
      'Ilfov',
      'Maramureș',
      'Mehedinți',
      'Mureș',
      'Neamț',
      'Olt',
      'Prahova',
      'Sălaj',
      'Satu Mare',
      'Sibiu',
      'Suceava',
      'Teleorman',
      'Timiș',
      'Tulcea',
      'Vâlcea',
      'Vaslui',
      'Vrancea',
    ],
  },
  RU: {
    name: 'Russia',
    provinceLabel: 'Region',
    zipRequired: true,
    provinces: [
      'Republic of Adygeya',
      'Altai Republic',
      'Altai Krai',
      'Amur Oblast',
      'Arkhangelsk Oblast',
      'Astrakhan Oblast',
      'Republic of Bashkortostan',
      'Belgorod Oblast',
      'Bryansk Oblast',
      'Republic of Buryatia',
      'Chechen Republic',
      'Chelyabinsk Oblast',
      'Chukotka Autonomous Okrug',
      'Chuvash Republic',
      'Republic of Dagestan',
      'Republic of Ingushetia',
      'Irkutsk Oblast',
      'Ivanovo Oblast',
      'Jewish Autonomous Oblast',
      'Kabardino-Balkarian Republic',
      'Kaliningrad Oblast',
      'Republic of Kalmykia',
      'Kaluga Oblast',
      'Kamchatka Krai',
      'Karachay–Cherkess Republic',
      'Republic of Karelia',
      'Kemerovo Oblast',
      'Khabarovsk Krai',
      'Republic of Khakassia',
      'Khanty-Mansi Autonomous Okrug',
      'Kirov Oblast',
      'Komi Republic',
      'Kostroma Oblast',
      'Krasnodar Krai',
      'Krasnoyarsk Krai',
      'Kurgan Oblast',
      'Kursk Oblast',
      'Leningrad Oblast',
      'Lipetsk Oblast',
      'Magadan Oblast',
      'Mari El Republic',
      'Republic of Mordovia',
      'Moscow',
      'Moscow Oblast',
      'Murmansk Oblast',
      'Nizhny Novgorod Oblast',
      'Novgorod Oblast',
      'Novosibirsk Oblast',
      'Omsk Oblast',
      'Orenburg Oblast',
      'Oryol Oblast',
      'Penza Oblast',
      'Perm Krai',
      'Primorsky Krai',
      'Pskov Oblast',
      'Rostov Oblast',
      'Ryazan Oblast',
      'Saint Petersburg',
      'Sakha Republic (Yakutia)',
      'Sakhalin Oblast',
      'Samara Oblast',
      'Saratov Oblast',
      'Republic of North Ossetia–Alania',
      'Smolensk Oblast',
      'Stavropol Krai',
      'Sverdlovsk Oblast',
      'Tambov Oblast',
      'Republic of Tatarstan',
      'Tomsk Oblast',
      'Tula Oblast',
      'Tver Oblast',
      'Tyumen Oblast',
      'Tyva Republic',
      'Udmurtia',
      'Ulyanovsk Oblast',
      'Vladimir Oblast',
      'Volgograd Oblast',
      'Vologda Oblast',
      'Voronezh Oblast',
      'Yamalo-Nenets Autonomous Okrug',
      'Yaroslavl Oblast',
      'Zabaykalsky Krai',
    ],
  },
  ZA: {
    name: 'South Africa',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'KwaZulu-Natal',
      'Limpopo',
      'Mpumalanga',
      'North West',
      'Northern Cape',
      'Western Cape',
    ],
  },
  KR: {
    name: 'South Korea',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Busan',
      'Chungbuk',
      'Chungnam',
      'Daegu',
      'Daejeon',
      'Gangwon',
      'Gwangju',
      'Gyeongbuk',
      'Gyeonggi',
      'Gyeongnam',
      'Incheon',
      'Jeju',
      'Jeonbuk',
      'Jeonnam',
      'Sejong',
      'Seoul',
      'Ulsan',
    ],
  },
  ES: {
    name: 'Spain',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'A Coruña',
      'Álava',
      'Albacete',
      'Alicante',
      'Almería',
      'Asturias',
      'Ávila',
      'Badajoz',
      'Balears',
      'Barcelona',
      'Burgos',
      'Cáceres',
      'Cádiz',
      'Cantabria',
      'Castellón',
      'Ceuta',
      'Ciudad Real',
      'Córdoba',
      'Cuenca',
      'Girona',
      'Granada',
      'Guadalajara',
      'Guipúzcoa',
      'Huelva',
      'Huesca',
      'Jaén',
      'La Rioja',
      'Las Palmas',
      'León',
      'Lleida',
      'Lugo',
      'Madrid',
      'Málaga',
      'Melilla',
      'Murcia',
      'Navarra',
      'Ourense',
      'Palencia',
      'Pontevedra',
      'Salamanca',
      'Santa Cruz de Tenerife',
      'Segovia',
      'Sevilla',
      'Soria',
      'Tarragona',
      'Teruel',
      'Toledo',
      'Valencia',
      'Valladolid',
      'Vizcaya',
      'Zamora',
      'Zaragoza',
    ],
  },
  TH: {
    name: 'Thailand',
    provinceLabel: 'Province',
    zipRequired: true,
    provinces: [
      'Amnat Charoen',
      'Ang Thong',
      'Bangkok',
      'Bueng Kan',
      'Buriram',
      'Chachoengsao',
      'Chai Nat',
      'Chaiyaphum',
      'Chanthaburi',
      'Chiang Mai',
      'Chiang Rai',
      'Chon Buri',
      'Chumphon',
      'Kalasin',
      'Kamphaeng Phet',
      'Kanchanaburi',
      'Khon Kaen',
      'Krabi',
      'Lampang',
      'Lamphun',
      'Loei',
      'Lopburi',
      'Mae Hong Son',
      'Maha Sarakham',
      'Mukdahan',
      'Nakhon Nayok',
      'Nakhon Pathom',
      'Nakhon Phanom',
      'Nakhon Ratchasima',
      'Nakhon Sawan',
      'Nakhon Si Thammarat',
      'Nan',
      'Narathiwat',
      'Nong Bua Lam Phu',
      'Nong Khai',
      'Nonthaburi',
      'Pathum Thani',
      'Pattani',
      'Pattaya',
      'Phangnga',
      'Phatthalung',
      'Phayao',
      'Phetchabun',
      'Phetchaburi',
      'Phichit',
      'Phitsanulok',
      'Phra Nakhon Si Ayutthaya',
      'Phrae',
      'Phuket',
      'Prachin Buri',
      'Prachuap Khiri Khan',
      'Ranong',
      'Ratchaburi',
      'Rayong',
      'Roi Et',
      'Sa Kaeo',
      'Sakon Nakhon',
      'Samut Prakan',
      'Samut Sakhon',
      'Samut Songkhram',
      'Saraburi',
      'Satun',
      'Sing Buri',
      'Sisaket',
      'Songkhla',
      'Sukhothai',
      'Suphan Buri',
      'Surat Thani',
      'Surin',
      'Tak',
      'Trang',
      'Trat',
      'Ubon Ratchathani',
      'Udon Thani',
      'Uthai Thani',
      'Uttaradit',
      'Yala',
      'Yasothon',
    ],
  },
  AE: {
    name: 'United Arab Emirates',
    provinceLabel: 'Emirate',
    zipRequired: false,
    provinces: [
      'Abu Dhabi',
      'Ajman',
      'Dubai',
      'Fujairah',
      'Ras al-Khaimah',
      'Sharjah',
      'Umm al-Quwain',
    ],
  },
  US: {
    name: 'United States',
    provinceLabel: 'State',
    zipRequired: true,
    provinces: [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'Virgin Islands',
      'Armed Forces Americas',
      'Armed Forces Europe',
      'Armed Forces Pacific',
    ],
  },
}

export const getAllCountries = (): Array<Country> => {
  const countries = []
  for (const [key, value] of Object.entries(isoCountries)) {
    countries.push({
      code: key,
      name: value,
    })
  }
  return countries
}

export const getCountryName = (countryCode: string): string => {
  // eslint-disable-next-line no-prototype-builtins
  if (isoCountries.hasOwnProperty(countryCode)) {
    return isoCountries[countryCode]
  } else {
    return countryCode
  }
}

export const getProvinces = (countryCode: string) => {
  return provinceData[countryCode]?.provinces || []
}

export const getCountryByCode = (countryCode: string): Country => {
  // eslint-disable-next-line no-prototype-builtins
  if (isoCountries.hasOwnProperty(countryCode)) {
    return {
      code: countryCode,
      name: isoCountries[countryCode],
    }
  } else {
    return null
  }
}
