import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { RotateLoader } from 'react-spinners'

const useStyles = makeStyles(() => ({
  root: {
    margin: '50vh 50vw',
  },
}))

export function Loader() {
  const classes = useStyles()
  return (
    <div className={classes.root} aria-label="loader">
      <RotateLoader size={20} margin={5} color={'#2985bc'} loading={true} />
    </div>
  )
}

export default Loader
