import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Address, Product, Variant } from '../../types'
import { PaymentAccountTypes } from '../../Constants'
import Title from '../Title/Title'
import PaymentRequestWrapper from '../PaymentRequestWrapper/PaymentRequestWrapper'
import { Button } from '@material-ui/core'
import StepsLoader from '../StepsLoader'

type Props = {
  onSuccess?: () => void
  product?: Product
  orderDetails?: {
    variant: Variant
    shippingDetails: Address
    billingDetails: Address
  }
  quantity?: number
  onPrevious?: (args: any) => void
  setOrderId: (arg: string) => void
  onClose: () => void
  onAddToOrdersHistory: (arg: string) => void
  /* To convert currency */
  shippingFlatRate?: number
  onContinue: (args: any) => void
  shippingCountry?: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    position: 'relative',
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    marginBottom: 15,
    color: '#000',
  },
  total: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    right: 10,
  },
  price: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    color: '#3F9AF1',
    right: 10,
  },
  usdPrice: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 7,
    marginBottom: 15,
    color: '#3F9AF1',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 200,
  },
  poweredByStripe: {
    width: 100,
    height: 21,
    marginBottom: 15,
  },
  arrowBack: {
    position: 'absolute',
    top: 35,
    left: 25,
  },
  paymentMethods: {
    backgroundImage: 'url("/static/images/cards.svg")',
    height: 25,
    width: 250,
    marginTop: 10,
    backgroundSize: 'cover',
  },
  currency: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 700,
    top: 2,
    right: -20,
  },
  elementsWrapper: {
    width: '100%',
    paddingRight: 25,
    paddingLeft: 25,
  },
  padding: {
    paddingRight: 25,
    paddingLeft: 25,
    paddingTop: 7,
    paddingBottom: 28,
    width: '100%',
  },
  cardButton: {
    ...theme.styles.button,
    height: 50,
    padding: 0,
    width: '-webkit-fill-available',
    ...theme.styles.blueButton,
    color: '#FBFBFB!important',
    textTransform: 'none!important',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  error: {
    fontSize: 12,
    fontFamily: 'Nunito',
    letterSpacing: '-0.02em',
    color: '#ff1744',
    fontWeight: 600,
  },
}))

const getBrandStripeAccount = (
  variant: Variant,
  brandUsesBBInvoices: boolean,
): string | undefined => {
  if (brandUsesBBInvoices) {
    return
  }
  return process.env.NODE_ENV !== 'production'
    ? process.env.REACT_APP_TEST_STRIPE_CONNECTED_ACCOUNT
    : variant?.stripeAccount
}

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Nunito:400,300,700,500',
    },
  ],
}

const Status = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}

export function PaymentMethode({
  product,
  orderDetails,
  quantity,
  onSuccess,
  onPrevious,
  setOrderId,
  onClose,
  onAddToOrdersHistory,
  shippingFlatRate,
  onContinue,
  shippingCountry,
}: Props) {
  const classes = useStyles()
  const [loaderStatus, setLoaderStatus] = React.useState<number>(0)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [status, setStatus] = React.useState(null)

  const brandUsesBBInvoices =
    product?.brand?.myShopPaymentAccount === PaymentAccountTypes.INVOICING
  const stripeAccount = getBrandStripeAccount(
    orderDetails.variant,
    brandUsesBBInvoices,
  )

  const stripeKey = process.env.REACT_APP_STRIPE_KEY
  const stripePromise = loadStripe(stripeKey, {
    stripeAccount,
  })

  return (
    <div className={classes.root}>
      <Title title="Payment Method" onClose={onClose} onPrevious={onPrevious} />

      {status !== Status.LOADING && (
        <>
          {(stripeAccount || brandUsesBBInvoices) && (
            <div className={classes.elementsWrapper}>
              <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <PaymentRequestWrapper
                  product={product}
                  orderDetails={orderDetails}
                  quantity={quantity}
                  onSuccess={onSuccess}
                  setOrderId={setOrderId}
                  onAddToOrdersHistory={onAddToOrdersHistory}
                  shippingFlatRate={shippingFlatRate}
                  shippingCountry={shippingCountry}
                  onErrorMessage={setErrorMessage}
                  onLoaderStatus={setLoaderStatus}
                  onStatus={setStatus}
                />
              </Elements>
            </div>
          )}

          <div className={classes.padding}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.cardButton}
              onClick={onContinue}
              disableElevation
              data-testid="stripe-credit-card"
            >
              <img
                // className={classes.poweredByStripe}
                alt="stripe logo"
                src="/static/images/stripe-credit-card.svg"
              />
            </Button>
          </div>
        </>
      )}

      {status === Status.ERROR && (
        <div className={classes.error}>{errorMessage}</div>
      )}

      {status === Status.LOADING && (
        <StepsLoader
          steps={[
            'Verifying payment',
            'Confirming stock level',
            `Placing order at <b>${product.brand.name}</b>`,
          ]}
          status={loaderStatus}
        />
      )}

      <div>
        <img
          className={classes.poweredByStripe}
          alt="stripe logo"
          src="/static/images/powered_by_stripe.svg"
        />
      </div>
    </div>
  )
}

export default PaymentMethode
