import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Template from '../../Template'

type Props = {
  error?: string
  resetErrorBoundary?: string
}
const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    margin: '70px 40px',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    letterSpacing: '-0.02em',
    textAlign: 'center',
  },
  h3: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    letterSpacing: '-0.02em',
    fontWeight: 'bold',
  },
  link: {
    color: '#999999',
  },
}))

function Error404() {
  const classes = useStyles()

  return (
    <Template>
      <div className={classes.container}>
        <h2 className={classes.title}>404 - Not Found</h2>
        <p className={classes.text}>
          <a href="https://myshop.io">Go Home </a>
        </p>
      </div>
    </Template>
  )
}

export default Error404
