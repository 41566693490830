import React from 'react'
import ApolloClient, { InMemoryCache } from 'apollo-boost'
import Helmet from 'react-helmet'
import ErrorBoundary from 'react-error-boundary'
import { ApolloProvider } from '@apollo/react-hooks'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, jssPreset } from '@material-ui/core/styles'
import { Router } from 'react-router-dom'
import { create as createJss } from 'jss'
import { JssProvider } from 'react-jss'
import history from './history'
import Root from './screens/Root'
import theme from './theme'
import 'typeface-nunito'
import LogRocket from 'logrocket'
import GA from './lib/GoogleAnalytics'
import Error404 from './screens/Errors/404'

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_APOLLO_SERVER,
  credentials: 'same-origin',
})

LogRocket.init('skibfk/myshop')

const jss = createJss(jssPreset() as any)

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        {GA.init() && <GA.RouteTracker />}
        <JssProvider jss={jss}>
          <ThemeProvider theme={theme}>
            <Helmet titleTemplate="%s - MyShop" defaultTitle="MyShop" />
            <CssBaseline />
            <ErrorBoundary FallbackComponent={Error404}>
              <React.Suspense fallback={<div>Loading...</div>}>
                <Root />
              </React.Suspense>
            </ErrorBoundary>
          </ThemeProvider>
        </JssProvider>
      </Router>
    </ApolloProvider>
  )
}

export default App
