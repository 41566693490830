import { Product } from '../../types'

export const getCountries = (productId: string, products: Product[]) => {
  const product = products.find(product => product.id === productId)
  return product ? product?.shippingCountries : []
}

export const getVariants = (
  productId: string,
  products: Product[],
  shippingCountry,
) => {
  const product = products.find(product => product.id === productId)
  if (!product) return []
  return product.variants.filter(variant =>
    variant.shippingCountries.includes(shippingCountry),
  )
}

export const getProduct = (productId: string, products: Product[]) => {
  const product = products.find(product => product.id === productId)
  return product || null
}

export const getShippingFlatRate = (
  productId: string,
  products: Product[],
  shippingCountry,
): number => {
  const product = products.find(product => product.id === productId)
  if (!product) return 0
  if (
    product.webshop.allowShippingCostForSpecificMarkets &&
    product.webshop.shippingFlatRate > 0 &&
    product.webShopPrice < product.webshop.freeShippingOnOrdersOver &&
    Array.isArray(product.webshop.flatRateShippingCountries) &&
    product.webshop.flatRateShippingCountries.includes(shippingCountry)
  ) {
    return product.webshop.shippingFlatRate
  } else {
    return 0
  }
}
